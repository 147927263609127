type SyncMessage = {
  type: 'NAVIGATION' | 'STATE_UPDATE' | 'ALERT';
  payload: {
    path?: string;
    state?: any;
    alert?: string;
  };
};

export class TabSyncService {
  private static instance: TabSyncService;
  private broadcastChannel: BroadcastChannel;

  private constructor() {
    this.broadcastChannel = new BroadcastChannel('app_sync');
    this.setupMessageListener();
  }

  public static getInstance(): TabSyncService {
    if (!TabSyncService.instance) {
      TabSyncService.instance = new TabSyncService();
    }
    return TabSyncService.instance;
  }

  private setupMessageListener() {
    this.broadcastChannel.onmessage = (event) => {
      const message: SyncMessage = event.data;
      this.handleMessage(message);
    };
  }

  private handleMessage(message: SyncMessage) {
    switch (message.type) {
      case 'NAVIGATION':
        if (message.payload.path) {
          window.dispatchEvent(new CustomEvent('app:navigation', { detail: message.payload.path }));
        }
        break;
    }
  }

  public syncNavigation(path: string) {
    const message: SyncMessage = {
      type: 'NAVIGATION',
      payload: { path },
    };
    this.broadcastChannel.postMessage(message);
  }
}
