import { Input, Select, SelectItem, cn } from '@nextui-org/react';
import { WizardFooter } from '../components/WizardFooter';
import { Project } from 'domain/modules';
import { useEffect, useState } from 'react';
import { useProjectCategory, useProjectDetail } from 'application/modules/projects';
import { CreateProjectSteps } from '../Wizard';
import { LeftPanel } from '../components/LeftPanel';
import { CreateProjectHeader } from '../components/Header';

interface IProps {
  project?: Project;
  isFetching: boolean;
  onChangeStep: (stepName: CreateProjectSteps, projectId: string) => void;
  isApiPending?: boolean;
  setIsApiPending?: (arg: boolean) => void;
}

export const TitleAndCategory = (props: IProps) => {
  const [name, setName] = useState(props.project && props.project.name ? props.project.name : '');
  const { setIsApiPending = () => null } = props;

  const [selectedCategory, setSelectedCategory] = useState(
    props.project && props.project.category_id ? String(props.project.category_id) : ''
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    props.project && props.project.sub_category_id ? String(props.project.sub_category_id) : ''
  );
  const [selectedType, setSelectedType] = useState(
    props.project && props.project.type_id ? String(props.project.type_id) : ''
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const {
    isFetching,
    categories,
    subCategories,
    types,
    getAllCategories,
    getSubCategoriesForCategory,
    getTypes,
  } = useProjectCategory();
  const { createProject, updateProject, recreateProject } = useProjectDetail();

  useEffect(() => {
    if (categories.length === 0) {
      getAllCategories();
    }
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      getSubCategoriesForCategory(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      getTypes(selectedSubCategory);
    }
  }, [selectedSubCategory]);

  const validateType = types && types.length > 0 ? selectedType && true : true;

  const onChangeCategory = (value: string) => {
    setSelectedCategory(value);
    setSelectedSubCategory('');
  };

  const onChangeSubCategory = (value: string) => {
    setSelectedSubCategory(value);
    setSelectedType('');
  };

  const isTitleValid = name && name.length <= 250 ? true : false;

  const onNext = async () => {
    setShowErrorMessage(true);
    if (isTitleValid && selectedCategory && selectedSubCategory && validateType) {
      setIsApiPending(true);
      if (props.project) {
        const tempProject = { ...props.project };
        tempProject.name = name;
        tempProject.category_id = selectedCategory;
        tempProject.sub_category_id = selectedSubCategory;
        tempProject.style_name = 'Standard';
        if (selectedType) {
          tempProject.type_id = selectedType;
        }

        const toRecreateProject =
          props.project.category_id !== selectedCategory ||
          props.project.sub_category_id !== selectedSubCategory;

        const data = toRecreateProject
          ? await recreateProject(tempProject.id, tempProject)
          : await updateProject(tempProject);

        if (data) {
          props.onChangeStep('customQuestions', data.id);
        }
      } else {
        const data = await createProject(
          name,
          selectedCategory,
          selectedSubCategory,
          selectedType ? selectedType : ''
        );
        if (data) {
          data.project_display_id = `SD_${data.unique_id}`;
          data.style_name = 'Standard';
          const newData = await updateProject(data);
          if (newData) {
            props.onChangeStep('customQuestions', newData.id);
          }
        }
      }
    }
  };

  return (
    <LeftPanel>
      <>
        <CreateProjectHeader title="Create Project" totalSteps={6} currentStep={1} isInitialStep />
        <div className="grow flex flex-col gap-6 2xl:gap-8">
          <Input
            variant="bordered"
            label="Project Title"
            placeholder="Enter project title"
            labelPlacement="outside"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            isRequired
            errorMessage={
              (name.length > 250 && 'Maximum number of characters is 250') ||
              (showErrorMessage && !name && 'Please provide a title for your project')
            }
            classNames={{
              label: 'text-[#CA0B4A] font-medium 2xl:text-xl',
              input: !name ? 'text-xs 2xl:text-medium' : '',
              inputWrapper: cn(
                'rounded-[16px] min-h-[48px] shadow-none',
                name.length > 250 ? 'border-1 border-solid border-[red]' : '',
                'data-[focus=true]:border-default'
              ),
              innerWrapper: 'pl-3',
            }}
          />

          <Select
            variant="bordered"
            items={categories}
            isLoading={isFetching === 'categories'}
            label="Choose Category"
            placeholder="Choose Category"
            labelPlacement="outside"
            onChange={(ev) => onChangeCategory(ev.target.value)}
            defaultSelectedKeys={selectedCategory ? [selectedCategory] : ''}
            isRequired
            classNames={{
              label: 'text-[#CA0B4A] font-medium 2xl:text-xl',
              value: !selectedCategory ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
              innerWrapper: 'pl-3',
              trigger: cn(
                'rounded-[16px] min-h-[48px] shadow-none data-[focus=true]:border-default'
              ),
              errorMessage: 'absolute',
            }}
            errorMessage={showErrorMessage && !selectedCategory && 'Please select a category'}
          >
            {(category) => (
              <SelectItem key={category.id} textValue={category.name}>
                <div className="flex gap-2 items-center">
                  <div className="flex flex-col">
                    <span className="text-small">{category.name}</span>
                    {category.description ? (
                      <span className="text-tiny text-default-400">{category.description}</span>
                    ) : null}
                  </div>
                </div>
              </SelectItem>
            )}
          </Select>

          {selectedCategory && (
            <Select
              variant="bordered"
              items={subCategories}
              label="Choose Subcategory"
              isLoading={isFetching === 'subcategories'}
              placeholder="Choose Sub Category"
              labelPlacement="outside"
              onChange={(ev) => onChangeSubCategory(ev.target.value)}
              defaultSelectedKeys={selectedSubCategory ? [selectedSubCategory] : ''}
              isRequired
              classNames={{
                label: 'text-[#CA0B4A] leading-[22px] font-medium 2xl:text-xl',
                value: !selectedSubCategory ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
                innerWrapper: 'pl-3',
                trigger: cn(
                  'rounded-[16px] min-h-[48px] shadow-none data-[focus=true]:border-default'
                ),
                errorMessage: 'absolute',
              }}
              errorMessage={
                showErrorMessage && !selectedSubCategory && 'Please choose a sub category'
              }
            >
              {(subcategory) => (
                <SelectItem key={subcategory.id} textValue={subcategory.name}>
                  <div className="flex gap-2 items-center">
                    <div className="flex flex-col">
                      <span className="text-small">{subcategory.name}</span>
                      {subcategory.description ? (
                        <span className="text-tiny text-default-400">
                          {subcategory.description || '-'}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </SelectItem>
              )}
            </Select>
          )}

          {selectedCategory && selectedSubCategory && (
            <Select
              variant="bordered"
              items={types}
              label="Choose Type"
              isLoading={isFetching === 'types'}
              placeholder="Choose Type"
              labelPlacement="outside"
              onChange={(ev) => setSelectedType(ev.target.value)}
              defaultSelectedKeys={selectedType ? [selectedType] : ''}
              isRequired
              classNames={{
                label: 'text-[#CA0B4A] leading-[22px] font-medium 2xl:text-xl',
                value: !selectedType ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
                innerWrapper: 'pl-3',
                trigger: cn(
                  'rounded-[16px] min-h-[48px] shadow-none data-[focus=true]:border-default'
                ),
                errorMessage: 'absolute',
              }}
              errorMessage={showErrorMessage && !selectedType && 'Please choose a type'}
            >
              {(subcategory) => (
                <SelectItem key={subcategory.id} textValue={subcategory.name}>
                  <div className="flex gap-2 items-center">
                    <div className="flex flex-col">
                      <span className="text-small">{subcategory.name}</span>
                      {subcategory.description ? (
                        <span className="text-tiny text-default-400">
                          {subcategory.description}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </SelectItem>
              )}
            </Select>
          )}
        </div>
        <WizardFooter
          onNext={onNext}
          nextTitle="Choose a Dimension"
          processing={props.isApiPending}
        />
      </>
    </LeftPanel>
  );
};
