import { useAppDispatch, useAppSelector } from 'application/shared';
import { getUser, logout, updateUser } from '../auth.thunks';
import { User } from 'domain/modules';
import { setDiscordReload } from '../auth.slice';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { user, beUser, fetchingBeUser, loginWithMagiclinkError, discordReload } = useAppSelector(
    (state) => state.auth
  );

  const _logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
  };

  const _getUser = () => {
    dispatch(getUser());
  };

  const _updateUser = (user: User) => {
    const { manager, ...user_obj } = user;
    console.log(`manager for ${user_obj.email}`, manager);
    dispatch(updateUser(user_obj));
  };

  const _discordReload = () => {
    dispatch(setDiscordReload());
  };

  return {
    user,
    beUser,
    getUser: _getUser,
    updateUser: _updateUser,
    logout: _logout,
    isLoading: fetchingBeUser,
    loginWithMagiclinkError,
    _discordReload,
    discordReload,
  };
};
