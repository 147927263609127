import {
  CircularProgress,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import { useAssetsUpload } from 'application/shared';
import { Brand } from 'domain/modules/brand';
import { AssetUploadResponse } from 'infra/api';
import { Button } from 'presentation/components';
import { supportedFileTypes } from 'presentation/constants';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Image } from '../Image';

interface IFileUploadProps {
  hasUploadedFiles: boolean;
  handleSaveUploadFile: (asset: AssetUploadResponse) => void;
  path: string;
  fileLength: number;
}

const FileUpload = ({
  hasUploadedFiles,
  handleSaveUploadFile,
  path,
  fileLength,
}: IFileUploadProps) => {
  const [loading, setIsLoading] = useState(false);
  const { upload } = useAssetsUpload();

  const handleChange = async (file: File) => {
    try {
      setIsLoading(true);
      const filePath = await upload(path, file);
      handleSaveUploadFile(filePath);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  if (fileLength >= 5) {
    return null;
  }

  return (
    <FileUploader handleChange={handleChange} name="file" types={supportedFileTypes}>
      {hasUploadedFiles ? (
        <div className="file_upload_area">
          <i className="ri-upload-cloud-2-line ri-lg mb-2" />
          {loading ? (
            <p className="font-medium text-sm flex gap-4 items-center">
              <CircularProgress aria-label="Loading..." size="sm" /> Uploading
            </p>
          ) : (
            <>
              <p className="font-medium text-sm text-[#0D1216]">
                <span className="text-[#136DC7]">Click here</span> to upload or drop media here
              </p>
              <p className="font-medium text-sm text-[#626F86] italic"> (add up to 5 files)</p>
              <p className="font-medium text-xs text-[#232323] italic">
                permissible files: .png, .svg, .jpeg
              </p>
            </>
          )}
        </div>
      ) : (
        <div className="bg-[#40576d12] w-20 h-20 rounded border flex justify-center items-center hover:bg-gray-100 cursor-pointer">
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <div className="text-center">
              <i className="ri-upload-cloud-2-line" />
              <p className="text-xs font-light">Add new</p>
            </div>
          )}
        </div>
      )}
    </FileUploader>
  );
};

interface FileData {
  id?: string;
  url: string;
  assetId?: string;
}

interface IProps {
  brandId: string;
  updateStaticObject: (key: keyof Brand, logos: Array<FileData>) => void;
  data: Array<FileData>;
  onSave: (shouldNavigate: boolean) => void;
  photos: FileData[];
  setPhotos: (val: FileData[]) => void;
  readOnly?: boolean;
}

export const Photos = ({ brandId, data, photos: files, setPhotos: setFiles, readOnly }: IProps) => {
  const { deleteAsset } = useAssetsUpload();

  useEffect(() => {
    setFiles(data);
  }, [data]);

  const handleSaveUploadFile = (asset: AssetUploadResponse) => {
    setFiles([
      ...files,
      {
        url: asset.url,
        assetId: asset.id,
      },
    ]);
  };

  const deleteFile = async (file: FileData) => {
    if (file.assetId) {
      await deleteAsset(file.assetId);
      const tempFiles = [...files].filter((i) => i.assetId !== file.assetId);
      setFiles(tempFiles);
    }
  };

  const handleDownload = (img: string) => {
    const anchor = document.createElement('a');
    anchor.href = img;
    anchor.download = img;
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div
      className={`ml-4 flex gap-2${files.length === 0 ? ' field_container justify-center' : ''}`}
    >
      {files.map((file) => {
        return (
          <div
            key={file.id}
            className="relative rounded  flex justify-center items-center cursor-pointer image_container"
          >
            <Image url={file.url} alt="Brand Photos" />

            <div className="absolute delete-icon items-center justify-center">
              <Dropdown>
                <DropdownTrigger>
                  <Button isIconOnly size="sm" variant="light">
                    <i className="ri-more-line text-white" />
                  </Button>
                </DropdownTrigger>

                <DropdownMenu aria-label="Static Actions">
                  <DropdownItem key="edit" onClick={() => handleDownload(file.url)}>
                    Download
                  </DropdownItem>

                  <DropdownItem
                    key="delete"
                    className="text-danger"
                    color="danger"
                    onClick={() => deleteFile(file)}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        );
      })}
      {readOnly ? (
        files.length === 0 && 'No Files Uploaded'
      ) : (
        <FileUpload
          hasUploadedFiles={files.length === 0}
          path={`brand/${brandId}/photos`}
          handleSaveUploadFile={handleSaveUploadFile}
          fileLength={files.length}
        />
      )}
    </div>
  );
};
