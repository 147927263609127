type SyncMessage = {
  type: 'NAVIGATION' | 'STATE_UPDATE' | 'ALERT';
  payload: {
    path?: string;
    state?: any;
    alert?: string;
  };
};

export class TabSyncService {
  private static instance: TabSyncService;

  private constructor() {
    this.setupMessageListener();
  }

  public static getInstance(): TabSyncService {
    if (!TabSyncService.instance) {
      TabSyncService.instance = new TabSyncService();
    }
    return TabSyncService.instance;
  }

  private setupMessageListener() {
    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) return;
      const message: SyncMessage = event.data;
      this.handleMessage(message);
    });
  }

  private handleMessage(message: SyncMessage) {
    switch (message.type) {
      case 'NAVIGATION':
        if (message.payload.path) {
          window.dispatchEvent(new CustomEvent('app:navigation', { detail: message.payload.path }));
        }
        break;
    }
  }

  public syncNavigation(path: string) {
    const message: SyncMessage = {
      type: 'NAVIGATION',
      payload: { path },
    };
    window.postMessage(message, window.location.origin);
  }
}
