/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/system';
import { useCheckout } from 'application/modules/subscription/hooks/useCheckout';
import { AddonPlan } from 'domain/modules/subscription/models/plans.model';

interface IProps {
  data: AddonPlan;
  cbInstance: any;
  onSuccess: () => void;
  setIsCheckingOut: (arg0: boolean) => void;
}

export const AddonCreditsCard = ({ data, cbInstance, onSuccess, setIsCheckingOut }: IProps) => {
  const { getHostedUrl } = useCheckout();

  const subscribeToAddon = (priceId: string) => {
    if (cbInstance) {
      cbInstance.openCheckout({
        hostedPage: () => {
          setIsCheckingOut(true);
          return getHostedUrl(priceId, 'addon');
        },
        success(hostedPageId: string) {
          console.log(hostedPageId);
          onSuccess();
          cbInstance.closeAll();
        },
        close: () => {
          setIsCheckingOut(false);
          console.log('checkout new closed');
        },
      });
    } else {
      alert('Payment gateway not initialized. Please try again.');
    }
  };

  return (
    <div className={cn('shadow-lg rounded-lg w-[27%] flex-1 flex flex-col relative pb-8 gap-0')}>
      <div className="bg-[#37393B] rounded-[8px_8px_0_0] text-center py-1">
        <h4 className="text-white text-lg font-semibold uppercase">{data.external_name}</h4>
      </div>
      <div className="flex flex-col items-center gap-6 py-4 px-10 justify-between h-full">
        <div className="text-center flex flex-col items-center">
          <h1 className=" text-5xl font-semibold mb-3">{data.metadata.credits}</h1>
          <span className="text-lg font-medium mb-3">Design Credits</span>
          <p className="text-center text-xs font-medium w-4/5">
            <ul className="text-sm font-medium text-left">
              {data.metadata.features.map((feature, index) => (
                <li
                  className="list-disc"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: feature }}
                />
              ))}
            </ul>
          </p>
        </div>
        <div>
          <h3 className="text-center text-2xl font-semibold uppercase mb-4">
            {data.price.currency_code} {data.price.price / 100}
          </h3>
          <Button
            className="bg-[#232323] rounded-full px-6 py-3 text-white text-lg font-semibold"
            onPress={() => {
              subscribeToAddon(data.price.id);
            }}
          >
            Buy Now
          </Button>
        </div>
      </div>
    </div>
  );
};
