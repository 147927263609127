import { Button } from 'presentation/components/Button';
import './style.css';
import { cn } from '@nextui-org/system';
import { CreateProject } from 'presentation/modules/company/screens/createProject';
import { Subscription } from 'domain/modules';

interface IProps {
  isFetchingSubscription: boolean;
  subscription: Subscription | null;
  onOpen: () => void;
}

export const NoProjectsAvailable = ({ isFetchingSubscription, onOpen, subscription }: IProps) => {
  return (
    <>
      <div className="no_project_available text-black mx-10 rounded-xl py-16 mt-6 mb-8">
        <p className="text-7xl font-normal">Your blank canvas awaits!</p>
        <p className="mt-4 max-w-unit-8xl text-center text-xl font-normal">
          Turn your ideas into stunning visuals. Click on &apos;Create Project&apos; to start your
          design journey.
        </p>
        <CreateProject
          Trigger={({ open }) => {
            return (
              <Button
                variant="solid"
                radius="full"
                className={cn('py-2 px-8 mt-10', 'bg-black text-white text-[16px] font-normal')}
                onClick={() => {
                  if (!isFetchingSubscription && !subscription) {
                    onOpen();
                  } else if (subscription) {
                    open();
                  }
                }}
              >
                Create Project
              </Button>
            );
          }}
        />
      </div>

      <div className={cn('flex items-center justify-center gap-12 mb-4')}>
        <video
          width="380"
          controls
          className="rounded-lg"
          controlsList="noremoteplayback nodownload"
        >
          <source src={import.meta.env.VITE_CREATE_PROJECT_SRC} type="video/mp4" />
          Your browser does not support HTML video.
        </video>

        <div className="w-[40%]">
          <h2 className="text-black text-3xl font-medium leading-[110%] mb-4">
            Not sure how to use Picassofusion?
          </h2>
          <p className="text-[#828282] text-lg font-medium leading-[125%]">
            Transform your ideas into stunning visuals with PicassoFusion. Simply purchase design
            credits and let our expert team bring your vision to life. Whether it&apos;s logos,
            graphics, or custom designs, we make your imagination a reality. Start today and elevate
            your projects with PicassoFusion!
          </p>
        </div>
      </div>
    </>
  );
};
