import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Card, Button, CardHeader, CardBody, CardFooter, cn } from '@nextui-org/react';
import logoWithTextImage from '../assets/logo/login_page_logo.png';

const iconMapper: any = {
  Alert: 'ri-information-line',
  Error: 'ri-close-circle-line',
  Success: 'ri-checkbox-circle-line',
};

type Toast = {
  id: number;
  title: string;
  message: string;
};

type ToastContextType = {
  addToast: (title: string, message: string) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);
const Logo = () => <img src={logoWithTextImage} alt="Picassofusion" />;

type ToastProps = {
  id: number;
  title: string;
  message: string;
  onClose: (id: number) => void;
};

const Toast: React.FC<ToastProps> = ({ id, title, message, onClose }) => (
  <Card style={{ padding: '5px', width: '425px' }}>
    <CardHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '30px',
          width: '30px',
          alignItems: 'center',
        }}
      ></div>
      <h3 style={{ color: 'black', marginLeft: '8px' }}>{'Picassofusion'}</h3>
    </CardHeader>
    <CardBody className="flex flex-row gap-2" style={{ alignItems: 'center' }}>
      <i className={iconMapper[title]} style={{ color: 'black', fontSize: '50px' }} />
      <p style={{ color: 'black', fontSize: '14px' }}>{message}</p>
    </CardBody>
    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
      <Button onPress={() => onClose(id)} className={cn('bg-[#99CF63]')}>
        Okay
      </Button>
    </div>
  </Card>
);

type ToastProviderProps = {
  children: ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback((title: string, message: string) => {
    const id = Date.now();
    setToasts((prev) => [...prev, { id, title, message }]);
  }, []);

  const removeToast = useCallback((id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '50%',
          transform: 'translate(-50%)',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        {toasts.map(({ id, title, message }) => (
          <Toast key={id} id={id} title={title} message={message} onClose={removeToast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
