import { Input } from '@nextui-org/react';
import { Brand } from 'domain/modules/brand';
import { Button } from 'presentation/components';
import { useEffect } from 'react';

interface MediaItem {
  id?: string;
  type: string;
  url: string;
  brandId?: string;
}

interface IProps {
  updateStaticObject: (key: keyof Brand, colors: Array<MediaItem>) => void;
  data: MediaItem[];
  socialMediaList: MediaItem[];
  setSocialMediaList: (val: MediaItem[]) => void;
}

export const SocialMedia = ({ data, socialMediaList, setSocialMediaList }: IProps) => {
  useEffect(() => {
    setSocialMediaList(data);
  }, [data.length]);

  const handleAddNew = () => {
    const tempData = Array.isArray(socialMediaList) ? [...socialMediaList] : [];
    tempData.push({
      id: `temp-${new Date().getTime()}`,
      type: '',
      url: '',
    });
    setSocialMediaList(tempData);
  };

  const handleChange = (id: string, typeOrUrl: 'type' | 'url', value: string) => {
    const tempData = Array.isArray(socialMediaList) ? [...socialMediaList] : [];
    const data = tempData.map((social) => {
      if (id === social.id) {
        if (typeOrUrl === 'type') {
          return {
            ...social,
            type: value,
          };
        } else {
          return {
            ...social,
            url: value,
          };
        }
      }

      return social;
    });

    setSocialMediaList(data);
  };

  const deleteItem = (id: string) => {
    const tempData = Array.isArray(socialMediaList) ? [...socialMediaList] : [];
    const newTemp = tempData.filter((i) => i.id !== id);

    setSocialMediaList(newTemp);
  };

  return (
    <div className="social_media_container">
      {!Array.isArray(socialMediaList)
        ? null
        : socialMediaList.map((socialMediaItem, index) => (
            <div className="p-0" key={socialMediaItem.id || index}>
              <div className="w-full flex gap-1 items-center">
                <Input
                  variant="bordered"
                  placeholder="Enter your Platform name"
                  value={socialMediaItem.type}
                  onChange={(ev) => handleChange(socialMediaItem.id || '', 'type', ev.target.value)}
                  classNames={{
                    inputWrapper: 'rounded-md border',
                  }}
                  className="w-3/12 bg-[#40576d12] rounded-lg"
                />
                <Input
                  variant="bordered"
                  placeholder="Enter your url here"
                  value={socialMediaItem.url}
                  onChange={(ev) => handleChange(socialMediaItem.id || '', 'url', ev.target.value)}
                  classNames={{
                    inputWrapper: 'rounded-md border',
                  }}
                  className="bg-[#40576d12] rounded-lg"
                />
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => deleteItem(socialMediaItem.id || '')}
                >
                  <i className="ri-delete-bin-5-line ri-xl"></i>
                </Button>
              </div>
            </div>
          ))}
      <div className="flex justify-end">
        <Button variant="light" startContent={<i className="ri-add-fill" />} onClick={handleAddNew}>
          Add New
        </Button>
      </div>
    </div>
  );
};
