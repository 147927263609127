import { Company } from 'domain/modules';
import { cn } from '@nextui-org/system';

import './style.css';
import { TOTAL_WIZARD_STEPS } from '../wizard';

interface IProps {
  children: React.ReactElement;
  title: string;
  description?: string;
  step: number;
  disableNavigate?: boolean;
  company: Company;
}

export const WizardContainer = ({ children, title, description, step }: IProps) => {
  return (
    <div className="wizard_container">
      <div className="flex flex-col gap-4">
        {/*<div
          className={cn(
            'bg-[#F0F0F0] rounded-full',
            'w-10 h-10 p-2 md:p-4',
            'flex items-center justify-center',
            'md:hidden'
          )}
        >
          <p className="text-black text-xs">
            {step}/{TOTAL_WIZARD_STEPS}
          </p>
        </div>*/}
        <div className={cn('flex items-end justify-between', 'w-[320px] md:w-[540px]')}>
          <div className=" flex flex-col items-start">
            <h1
              className={cn(
                'text-[#232323] font-bold',
                'text-lg md:text-2xl xl:text-2xl',
                'mb-3 md:mb-2'
              )}
            >
              {title}
            </h1>
            {description ? (
              <h5 className={cn('text-[#232323]', 'text-sm')}>{description}</h5>
            ) : null}
          </div>

          <div
            className={cn(
              'bg-[#F0F0F0] rounded-full',
              'w-10 h-10 p-2 md:p-4',
              'flex items-center justify-center',
              'hidden md:flex'
            )}
          >
            <p className="text-black text-xs">
              {step}/{TOTAL_WIZARD_STEPS}
            </p>
          </div>
        </div>
      </div>

      <div className="wizard-content mt-8">{children}</div>
    </div>
  );
};
