import { DropdownItem, DropdownMenu } from '@nextui-org/react';
import { Project, TaskStatusType } from 'domain/modules';

interface IProps {
  status: TaskStatusType;
  updateStatus: () => void;
  openProject: (val: boolean) => void;
  confirmationModalOnOpen: () => void;
  setIsCopy: (val: boolean) => void;
  projectsInLine: () => Project[];
  onClose: () => void;
}

export const DropdownItemCustom = ({
  status,
  updateStatus,
  openProject,
  confirmationModalOnOpen,
  setIsCopy,
  projectsInLine,
  onClose,
}: IProps) => {
  switch (status) {
    case 'IN_ACTIVE':
      return (
        <DropdownMenu aria-label="Static Actions">
          <DropdownItem key="edit" onClick={() => openProject(true)}>
            Edit
          </DropdownItem>

          <DropdownItem
            key="delete"
            className="text-danger data-[hover]:bg-transparent data-[hover]:text-[#FF0000]"
            color="danger"
            onClick={() => {
              setIsCopy(false);
              confirmationModalOnOpen();
              onClose();
            }}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      );
    case 'TODO':
      return (
        <DropdownMenu
          aria-label="Static Actions"
          disabledKeys={projectsInLine().length > 0 ? ['move'] : []}
        >
          <DropdownItem key="move" onClick={updateStatus}>
            Move to next state
          </DropdownItem>
          <DropdownItem key="edit" onClick={() => openProject(true)}>
            Edit
          </DropdownItem>
          <DropdownItem
            key="copy"
            onClick={() => {
              setIsCopy(true);
              confirmationModalOnOpen();
              onClose();
            }}
          >
            Copy
          </DropdownItem>
          <DropdownItem
            key="delete"
            className="text-danger data-[hover]:bg-transparent data-[hover]:text-[#FF0000]"
            color="danger"
            onClick={() => {
              setIsCopy(false);
              confirmationModalOnOpen();
              onClose();
            }}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      );
    case 'START_DESIGNING':
      return (
        <DropdownMenu aria-label="Static Actions">
          <DropdownItem key="move" onClick={updateStatus}>
            Move to Previous state
          </DropdownItem>
        </DropdownMenu>
      );
    case 'IN_REVIEW':
      return (
        <DropdownMenu aria-label="Static Actions">
          <DropdownItem key="move" onClick={updateStatus}>
            Move to Next state
          </DropdownItem>
        </DropdownMenu>
      );
    default:
      return null;
  }
};
