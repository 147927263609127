import { Input, Select, SelectItem } from '@nextui-org/react';
import { useAuth } from 'application/modules';
import { Button } from 'presentation/components';
import { useEffect, useRef, useState } from 'react';

const styles = {
  inputWrapper: ['shadow-none', 'border-0'],
};

const EditButton = ({
  onEdit,
  isDisabled,
  onSaveWebsite,
}: {
  onEdit?: () => void;
  isDisabled: boolean;
  onSaveWebsite?: () => void;
}) => {
  const handleEdit = () => {
    if (onEdit) {
      onEdit();
    }

    if (onSaveWebsite) {
      onSaveWebsite();
    }
  };

  return (
    <Button isIconOnly onClick={() => handleEdit()} variant="light">
      {isDisabled ? <i className="ri-check-line" /> : <i className="ri-pencil-line" />}
    </Button>
  );
};

const TextInput = ({
  type = 'text',
  label,
  value,
  onChangeWebsite,
  noEdit,
  isWebsite,
  onSaveWebsite,
}: {
  type?: string;
  label: string;
  value: string | undefined;
  onChangeWebsite?: (val: string) => void;
  noEdit?: boolean;
  isWebsite: boolean;
  onSaveWebsite?: () => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    if (editEnabled) {
      inputRef.current && inputRef.current.focus();
    }
  }, [editEnabled]);

  const onChnageInput = (value: string) => {
    if (onChangeWebsite) {
      onChangeWebsite(value);
    }
  };

  const isUrlValid = (string: string | undefined) => {
    if (string) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
        'i'
      );
      return pattern.test(string);
    } else {
      return true;
    }
  };

  return (
    <Input
      ref={inputRef}
      type={type}
      label={label}
      value={value}
      labelPlacement="outside"
      placeholder={`Enter your ${label}`}
      variant="underlined"
      classNames={styles}
      disabled={!editEnabled}
      endContent={
        noEdit ? null : (
          <EditButton
            onEdit={() => setEditEnabled(!editEnabled)}
            isDisabled={editEnabled || (isWebsite && !isUrlValid(value))}
            onSaveWebsite={onSaveWebsite}
          />
        )
      }
      onChange={(ev) => onChnageInput(ev.target.value)}
      errorMessage={isWebsite && !isUrlValid(value) ? 'Invalid URL' : ''}
    />
  );
};

export const ProfileScreen = () => {
  const { beUser, updateUser, getUser } = useAuth();
  const [website, setWebsite] = useState(beUser ? beUser.website : '');

  const onChangeWebsite = (value: string) => {
    setWebsite(value);
  };

  const onChangeNewsletter = (value: string) => {
    const selectedValue = value;
    const newValue = selectedValue === 'true';

    if (beUser && beUser.monthly_newsletter !== newValue) {
      const tempUser = { ...beUser };
      tempUser.monthly_newsletter = newValue;
      updateUser(tempUser);
    }
  };

  const onSaveWebsite = () => {
    if (beUser && beUser.website !== website) {
      const tempUser = { ...beUser };
      tempUser.website = website;
      updateUser(tempUser);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="flex flex-col gap-6 px-12">
      <div className="grid grid-cols-2 gap-4">
        <TextInput label="Name" value={beUser?.name} noEdit isWebsite={false} />
        <TextInput type="email" label="Email" value={beUser?.email} noEdit isWebsite={false} />
        <TextInput
          label="Manager Name"
          value={beUser?.manager?.email || '-'}
          noEdit
          isWebsite={false}
        />
        <TextInput
          label="Discord Email"
          value={beUser?.discord_mail || '-'}
          noEdit
          isWebsite={false}
        />
      </div>
      <Select
        labelPlacement="outside"
        variant="underlined"
        label="Monthly Newsletter"
        onChange={(ev) => onChangeNewsletter(ev.target.value)}
        defaultSelectedKeys={[`${beUser?.monthly_newsletter}`]}
        isDisabled={false}
        classNames={{
          trigger: ['shadow-none', 'border-0'],
        }}
        // endContent={<EditButton isDisabled={false} />}
      >
        <SelectItem key="true" value="true">
          Yes
        </SelectItem>
        <SelectItem key="false" value="false">
          No
        </SelectItem>
      </Select>
      <TextInput
        type="url"
        label="Website"
        value={website}
        onChangeWebsite={onChangeWebsite}
        isWebsite
        onSaveWebsite={onSaveWebsite}
      />
    </div>
  );
};
