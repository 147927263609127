import { Textarea, cn } from '@nextui-org/react';
import { useState } from 'react';
import { WizardFooter } from '../components/WizardFooter';
import { Project } from 'domain/modules';
import { useProjectDetail } from 'application/modules/projects';
import { CreateProjectSteps } from '../Wizard';
import { LeftPanel } from '../components/LeftPanel';
import { CreateProjectHeader } from '../components/Header';
import { log } from 'infra/providers/firebase';
import { Button } from 'presentation/components';
import { LOGO_CATEGORY_ID } from '../constants';

interface IProps {
  project?: Project;
  isFetching: boolean;
  onChangeStep: (stepName: CreateProjectSteps, projectId?: string) => void;
  setIsApiPending?: (arg: boolean) => void;
  isApiPending?: boolean;
}

export const DescriptionAndNarration = (props: IProps) => {
  const { setIsApiPending = () => null } = props;

  const [description, setDescription] = useState(
    props.project && props.project.description ? props.project.description : ''
  );
  const [context, setContext] = useState(
    props.project && props.project.context ? props.project.context : ''
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { updateProject } = useProjectDetail();

  const isValid =
    description.trim().split(/\s+/).length <= 300 && context.trim().split(/\s+/).length <= 300
      ? true
      : false;

  const onNext = async () => {
    setShowErrorMessage(true);
    if (props.project && props.project.category_id === LOGO_CATEGORY_ID) {
      if (description) {
        setIsApiPending(true);
        const tempProject = { ...props.project };
        tempProject.description = description;
        const data = await updateProject(tempProject);
        if (data) {
          props.onChangeStep('additionalQuestions', data.id);
        }
      }
    } else {
      if (props.project && description && context && isValid) {
        setIsApiPending(true);
        const tempProject = { ...props.project };
        tempProject.description = description;
        tempProject.context = context;
        const data = await updateProject(tempProject);
        if (data) {
          props.onChangeStep('additionalQuestions', data.id);
        }
      }
    }
    log('Test');
  };

  const onPrev = () => {
    if (props.project?.category_id === LOGO_CATEGORY_ID) {
      props.onChangeStep('customQuestions');
    } else {
      props.onChangeStep('brandSelection');
    }
  };

  const handleDownload = () => {
    const contentFile = import.meta.env.VITE_CONTENT_GUIDE;
    window.open(contentFile, '_blank');
  };

  return (
    <LeftPanel>
      <>
        <CreateProjectHeader
          title="Create Design Task"
          totalSteps={props.project?.category_id === LOGO_CATEGORY_ID ? 9 : 5}
          currentStep={props.project?.category_id === LOGO_CATEGORY_ID ? 8 : 4}
        />

        <div className="grow flex flex-col gap-4">
          <div>
            <h4 className="text-[#232323] leading-[22px] font-semibold text-base text-wrap 2xl:text-xl">
              What content would you like included in the design?{' '}
              <span className="text-[#CA0B4A]">*</span>
            </h4>
            <p className="text-[#626F86] text-xs">
              Provide details like headlines, body text, slogans, contact info, event details,
              dates, venue, hashtags, etc.
              <span>
                <Button
                  size="sm"
                  variant="light"
                  className="p-0 ml-2 text-[#2C62B4] leading-[22px] font-medium data-[hover]:bg-transparent"
                  startContent={<i className="ri-download-2-line" />}
                  onClick={handleDownload}
                >
                  Content Guide Document
                </Button>
              </span>
            </p>
            <Textarea
              isRequired
              placeholder="Enter Content for Design"
              description={`${description.length} of 1500 characters`}
              classNames={{
                label: 'text-[#CA0B4A] leading-[22px] font-medium text-wrap 2xl:text-xl',
                input: cn('max-h-[120px]', !description ? 'text-xs 2xl:text-medium' : ''),
                inputWrapper: cn(
                  'rounded-lg',
                  description.length > 1500 ? 'border-1 border-solid border-[red]' : ''
                ),
                description: 'text-right',
              }}
              value={description}
              maxLength={1500}
              onChange={(ev) => setDescription(ev.target.value)}
              errorMessage={
                (description.length > 1500 && 'Limit exceeded') ||
                (showErrorMessage &&
                  !description &&
                  'Please provide the necessary content for the design')
              }
            />
          </div>

          {props.project && props.project.category_id !== LOGO_CATEGORY_ID ? (
            <Textarea
              isRequired
              label="Want to specify the narration?"
              labelPlacement="outside"
              placeholder="Feel free to describe the Narration"
              description={`${context.length} of 1500 characters`}
              classNames={{
                label: 'text-[#CA0B4A] leading-[22px] font-medium text-wrap 2xl:text-xl',
                input: cn('max-h-[120px]', !context ? 'text-xs 2xl:text-medium' : ''),
                inputWrapper: cn(
                  'rounded-lg',
                  context.length > 1500 ? 'border-1 border-solid border-[red]' : ''
                ),
                description: 'text-right',
              }}
              maxLength={1500}
              value={context}
              onChange={(ev) => setContext(ev.target.value)}
              errorMessage={
                (context.length > 1500 && 'Limit exceeded') ||
                (showErrorMessage && !context && 'Please provide the narrative for design')
              }
            />
          ) : null}
        </div>
        <WizardFooter
          onNext={onNext}
          onPrev={onPrev}
          nextTitle="Add Additional Files"
          processing={props.isApiPending}
        />
      </>
    </LeftPanel>
  );
};
