import { cn } from '@nextui-org/system';
import pdf from 'presentation/assets/file_types/pdf.svg';
import mp3 from 'presentation/assets/file_types/mp3.svg';
// import mp4 from 'presentation/assets/file_types/mp4.svg';
import ppt from 'presentation/assets/file_types/ppt.svg';
import word from 'presentation/assets/file_types/word.svg';

interface FileData {
  id?: string;
  media_url: string;
  assetId: string;
}

interface IProps {
  files: FileData[];
  deleteFile?: (file: FileData) => void;
  isEditMode?: boolean;
  fromDetails?: boolean;
  size?: 'sm' | 'md';
  anchorElement?: boolean;
}

interface RenderProps {
  file: FileData;
}

const RenderCustomFile = ({ file }: RenderProps) => {
  const fileType = !file.media_url
    ? 'none'
    : file.media_url.split('.')[file.media_url.split('.').length - 1];

  switch (fileType) {
    case 'pdf':
      return (
        <img src={pdf} alt="pdf" className="object-cover w-[calc(100%+5px)] h-[calc(100%+5px)]" />
      );
    case 'mp3':
      return (
        <img src={mp3} alt="mp3" className="object-cover  w-[calc(100%+5px)] h-[calc(100%+5px)]" />
      );
    case 'mp4':
      return (
        <video
          src={file.media_url}
          className="object-cover w-[calc(100%+5px)] h-[calc(100%+5px)]"
        />
      );
    case 'ppt':
      return (
        <img src={ppt} alt="ppt" className="object-cover  w-[calc(100%+5px)] h-[calc(100%+5px)]" />
      );
    case 'pptx':
      return (
        <img src={ppt} alt="pptx" className="object-cover  w-[calc(100%+5px)] h-[calc(100%+5px)]" />
      );
    case 'word':
      return (
        <img
          src={word}
          alt="word"
          className="object-cover  w-[calc(100%+5px)] h-[calc(100%+5px)]"
        />
      );
    default:
      return <img src={file.media_url} alt="default" className="object-cover w-full h-full" />;
  }
};

export const FileRenderer = ({
  files,
  deleteFile,
  isEditMode,
  fromDetails,
  size = 'md',
  anchorElement = false,
}: IProps) => {
  return (
    <>
      {files.map((file) => (
        <div
          key={file.id}
          className={cn(
            'image_container',
            'flex justify-center items-center',
            'relative  rounded border  hover:bg-gray-100 cursor-pointer overflow-hidden',
            size === 'md' ? 'w-20 h-20' : 'w-[70px] h-[70px]'
          )}
        >
          {anchorElement ? (
            <a href={file.media_url} target="_blank" rel="noreferrer">
              <RenderCustomFile file={file} />
            </a>
          ) : (
            <RenderCustomFile file={file} />
          )}
          {!deleteFile || (fromDetails && !isEditMode) ? null : (
            <div
              className="absolute delete-icon items-center justify-center"
              onClick={() => deleteFile(file)}
            >
              <i className="ri-close-fill" />
            </div>
          )}
        </div>
      ))}
    </>
  );
};
