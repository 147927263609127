import { useCompany } from 'application/modules/company';
import { WizardContainer } from '../components/WizardContainer';
import { useNavigate } from 'react-router-dom';
import { Button } from 'presentation/components';
import { Company } from 'domain/modules';
import { useState } from 'react';
import { Radio, RadioGroup, Select, SelectItem, cn } from '@nextui-org/react';
import { useAuth } from 'application/modules';

interface IProps {
  fieldName: string;
  company: Company;
  updateData: (company: Company, stepName: string) => void;
  isupdating: boolean;
}

export const Step3 = ({ fieldName, company, updateData, isupdating }: IProps) => {
  const [selectedValue, setSelectedValue] = useState(company.know_about_picasso);
  const [receiveUpdates, setReceiveUpdates] = useState(company.receive_updates);
  const { getLovDataForField } = useCompany();
  const optionData = getLovDataForField(fieldName);
  const navigate = useNavigate();
  const { beUser, updateUser } = useAuth();

  const onNext = async () => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { id, userId, ...rest } = company;
    updateData(
      {
        ...rest,
        know_about_picasso: selectedValue,
        receive_updates: receiveUpdates,
      },
      '4'
    );

    if (beUser) {
      const tempUser = { ...beUser, company_step_number: '4' };
      tempUser.monthly_newsletter = receiveUpdates === 'yes';
      updateUser(tempUser);
    }
  };

  const onBack = (step: number) => {
    navigate(`/company/onboarding/step${step}`);
  };

  const onChange = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <WizardContainer
      title="New to Picassofusion?"
      step={3}
      company={company}
      disableNavigate={!selectedValue || !receiveUpdates}
    >
      <div className="w-full flex justify-center">
        <div className=" flex flex-col gap-5 justify-end md:justify-center md:items-center">
          <Select
            fullWidth
            onChange={(ev) => onChange(ev.target.value)}
            defaultSelectedKeys={selectedValue ? [selectedValue] : ''}
            placeholder="How did you hear about Picassofusion?"
            variant="bordered"
            radius="lg"
            classNames={{
              base: 'w-80 md:w-[540px] min-h-14',
              trigger: cn(
                'bg-white border border-solid border-[#D9D9D9] max-w-none rounded-[20px] text-center',
                'px-5 py-3'
              ),
              popoverContent: 'bg-white text-black',
              selectorIcon: 'mr-2',
              listbox: 'p-3',
              value: !selectedValue ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
            }}
          >
            {optionData.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>

          <div className="flex flex-col gap-3 items-center justify-start md:flex-row md:justify-between w-full">
            <h4 className="text-[16px] font-medium">Receive Newsletters/ EmailUpdates?</h4>
            <div className="flex gap-8 ">
              <RadioGroup
                onChange={(ev) => setReceiveUpdates(ev.target.value)}
                value={receiveUpdates}
                classNames={{
                  wrapper: 'md:flex md:flex-row md:gap-3',
                }}
                className="data-[orientation=vertical]:flex-row"
              >
                <Radio
                  value="yes"
                  className={cn(
                    'g-white border border-solid border-[#D9D9D9] max-w-none',
                    'rounded-xl text-center',
                    'pl-5 pr-7 py-3 m-0 mb-3 md:mb-0 md:pr-3 md:py-2'
                  )}
                  classNames={{
                    base: 'w-80 md:w-[100px]',
                    label: 'ml-3 md:ml-1 text-black text-sm',
                    wrapper:
                      'border-[#D9D9D9] bg-transparent data-[selected=true]:border-[#37393b]',
                    control: 'bg-black border-white',
                  }}
                >
                  Yes
                </Radio>
                <Radio
                  value="no"
                  className={cn(
                    'g-white border border-solid border-[#D9D9D9] max-w-none',
                    'rounded-xl text-center',
                    'pl-5 pr-7 py-3 m-0 mb-3 md:mb-0 md:pr-3 md:py-2'
                  )}
                  classNames={{
                    base: 'w-80 md:w-[100px]',
                    label: 'ml-3 md:ml-1 text-black text-sm',
                    wrapper:
                      'border-[#D9D9D9] bg-transparent data-[selected=true]:border-[#37393b]',
                    control: 'bg-black border-white',
                  }}
                >
                  No
                </Radio>
              </RadioGroup>
            </div>
          </div>

          <div className="flex gap-5 mt-3 md:w-full">
            <Button
              size="lg"
              fullWidth
              onClick={() => onBack(2)}
              radius="full"
              startContent={<i className="ri-arrow-left-line" />}
              className={cn('bg-[#F0F0F0]', 'text-black font-medium')}
            >
              Back
            </Button>
            <Button
              size="lg"
              radius="full"
              className={cn('bg-[#99CF63]')}
              fullWidth
              isLoading={isupdating}
              disabled={isupdating}
              onClick={onNext}
              isDisabled={!selectedValue || !receiveUpdates}
              endContent={<i className="ri-arrow-right-line" />}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </WizardContainer>
  );
};
