/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  cn,
} from '@nextui-org/react';
import logo_with_title_black from '../../../../../assets/logo/logo_with_title_black.png';

export const OnboardingNavbar = ({ avatarUrl, userName, email, onLogout }: any) => {
  return (
    <Navbar
      maxWidth="2xl"
      classNames={{
        wrapper: 'px-0',
      }}
    >
      <NavbarContent justify="start">
        <NavbarBrand className="mr-4">
          <img
            src={logo_with_title_black}
            alt="logo_with_title_black"
            className={cn('object-contain ', 'w-[110px] md:w-[150px]')}
          />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent as="div" className="items-center" justify="end">
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <div className="flex items-center gap-3">
              <Avatar
                as="button"
                className="transition-transform"
                color="secondary"
                size="sm"
                name={userName ? userName[0] : ''}
                src={avatarUrl}
              />
              <p className="text-black text-sm font-normal hidden md:block">Welcome {userName}</p>
              <i className="ri-arrow-down-s-fill ri-2x" />
            </div>
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            <DropdownItem key="profile" className="h-14 gap-2">
              <p className="font-semibold">Signed in as</p>
              <p className="font-semibold">{email}</p>
            </DropdownItem>
            <DropdownItem key="logout" color="danger" onClick={onLogout}>
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>
    </Navbar>
  );
};
