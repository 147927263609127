import axios from 'axios';
import { IUserService, User } from 'domain/modules';
import has from 'lodash/has';

const UNAUTHORIZED = 401;

class UserService implements IUserService {
  authInterceptor: number | null = null;

  setBearerToken(token: string): void {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  }

  registerAuthInterceptor(onUnAuthorized: () => void) {
    this.authInterceptor = axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
          onUnAuthorized();
        }
        return Promise.reject(error);
      }
    );
  }

  unregisterAuthInterceptor() {
    if (this.authInterceptor) axios.interceptors.request.eject(this.authInterceptor);
  }

  getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get<User>('/api/user')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  updateUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .put<User>(`/api/user/${user.id}`, user)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  updateDiscordMail(data: { id: string; discordMail: string }): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .post<User>(`/api/discord/update-email`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Something went wrong!' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getRedirect(data: { user_id: any }): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post<string>(`/api/discord/redirect`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Something went wrong!' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const userService = new UserService();
