import { Button } from 'presentation/components';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title: string;
  noBack?: boolean;
}

export const Header = ({ title, noBack }: IProps) => {
  const navigate = useNavigate();

  return (
    <div className="pt-7 pb-5 flex items-center gap-3">
      {noBack ? null : (
        <Button isIconOnly variant="light" size="lg" onPress={() => navigate(-1)}>
          <i className="ri-arrow-left-s-line ri-3x" />
        </Button>
      )}

      <h1 className="text-3xl font-bold">{title}</h1>
    </div>
  );
};
