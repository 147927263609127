import { AuthGuard } from 'presentation/components/Guards/AuthGuard';
import { UnAuthGuard } from 'presentation/components/Guards/UnAuthGuard';
import { LoginScreen } from 'presentation/modules/auth/screens/login';
import { WorkboardScreen } from 'presentation/modules/tasks/screens/workboard';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { FirebaseWrapper } from './FirebaseWrapper';
import { Layout } from 'presentation/components/layout';
import { ProjectsScreen } from 'presentation/modules/company/screens/projects';
import { BrandScreen } from 'presentation/modules/brand/screens';
import { BrandListing } from 'presentation/modules/brand/screens/useCases/BrandListing';
import { BrandDetail } from 'presentation/modules/brand/screens/useCases/BrandDetail';
import { AccountScreen } from 'presentation/modules/auth/screens/account';
import { CompanySetupScreen } from 'presentation/modules/company/screens/setup';
import { FaqScreen } from 'presentation/modules/misc/screens/faqs';
import { TutorialsScreen } from 'presentation/modules/misc/screens/tutorials';
import CompleteSignin from 'presentation/components/CompleteSignin';
import { ProfileScreen } from 'presentation/modules/auth/screens/profile';
import { PlansScreen } from 'presentation/modules/subscription/screens/plans';
import { ProjectDetailScreen } from 'presentation/modules/company/screens/project-detail';
import { AdminApp } from 'adminApp';
import { Listings } from 'presentation/modules/company/screens/projects/components/Listings';
import { ListingsByMonth } from 'presentation/modules/company/screens/projects/components/ListingByMonth';
import { ListingsByProject } from 'presentation/modules/company/screens/projects/components/ListingByProject';
import { AdminAuthGuard } from 'adminApp/AdminAuthGuard';
import { ListingByProjectIterations } from 'presentation/modules/company/screens/projects/components/ListingByProjectIterations';
import { ListingSampleProject } from 'presentation/modules/company/screens/projects/components/ListingSampleProject';
import { DiscordComplete } from 'presentation/components/DiscordConnect/complete';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <UnAuthGuard component={<LoginScreen />} />,
  },
  {
    path: '/signin/complete',
    element: <UnAuthGuard component={<CompleteSignin />} />,
  },
  {
    path: '/company/onboarding/:step',
    element: <AuthGuard component={<CompanySetupScreen />} />,
  },
  {
    path: '/admin',
    element: <AdminAuthGuard component={<AdminApp />} />,
  },
  {
    path: '/discord',
    element: <AuthGuard component={<DiscordComplete />} />,
  },
  {
    path: '/',
    element: <AuthGuard component={<Layout />} />,
    children: [
      {
        path: '/workboard',
        element: <WorkboardScreen />,
      },
      {
        path: '/projects/listing',
        element: <ProjectsScreen />,
        children: [
          {
            path: '/projects/listing',
            element: <Listings />,
          },
          {
            path: '/projects/listing/month',
            element: <ListingsByMonth />,
          },
          {
            path: '/projects/listing/project/:projectId',
            element: <ListingByProjectIterations />,
          },
          {
            path: '/projects/listing/project/:projectId/iteration/:iterationId',
            element: <ListingsByProject />,
          },
          {
            path: '/projects/listing/project/sample',
            element: <ListingSampleProject />,
          },
        ],
      },
      {
        path: '/projects/:projectId',
        element: <ProjectDetailScreen />,
      },
      {
        path: '/faq',
        element: <FaqScreen />,
      },
      {
        path: '/tutorials',
        element: <TutorialsScreen />,
      },
      {
        path: '/account',
        element: <AccountScreen />,
        children: [
          {
            path: '/account',
            element: <ProfileScreen />,
          },
          {
            path: '/account/plans',
            element: <PlansScreen />,
          },
        ],
      },
      {
        path: '/brand',
        element: <BrandScreen />,
        children: [
          {
            path: '/brand',
            element: <BrandListing />,
          },
          {
            path: '/brand/:brandId',
            element: <BrandDetail readOnly />,
          },
          {
            path: '/brand/:brandId/edit',
            element: <BrandDetail readOnly={false} />,
          },
        ],
      },
    ],
  },
]);

export const RootRoute = () => {
  return (
    <FirebaseWrapper>
      <RouterProvider router={router} />
    </FirebaseWrapper>
  );
};
