import { useAuth } from 'application/modules/auth';
import { Subscription } from 'domain/modules/subscription';
import { AddonPlan, Plan } from 'domain/modules/subscription/models/plans.model';
import { subscriptionService } from 'infra/api';
import { ModalKeyType } from 'presentation/modules/tasks/screens/workboard';
import { useState } from 'react';

export const useSubscription = () => {
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);
  const [isFetchingPlans, setIsFetchingPlans] = useState(false);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [plans, setPlans] = useState<Array<Plan>>([]);
  const [addOnPlans, setAddOnPlans] = useState<Array<AddonPlan>>([]);
  const { _discordReload } = useAuth();
  const get = async () => {
    try {
      setIsFetchingSubscription(true);
      const data = await subscriptionService.getUserSubscriptions();
      setSubscription(data);
      _discordReload();
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetchingSubscription(false);
    }
  };

  const getPlans = async () => {
    try {
      setIsFetchingPlans(true);
      const data = await subscriptionService.getAllPlans();
      data.sort((a, b) => a.price.price - b.price.price);
      setPlans(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetchingPlans(false);
    }
  };

  const getAddonPlans = async () => {
    try {
      const data = await subscriptionService.getAllAddOnPlans();
      data.sort((a, b) => a.price.price - b.price.price);
      setAddOnPlans(data);
    } catch (ex) {
      console.log(ex);
    }
  };

  const getIsPlanPurchased = async (afterSuccess: (arg0: ModalKeyType) => void) => {
    const res = await get();
    if (res) {
      afterSuccess('success');
      return;
    }

    let apiTriggerCount = 0;
    const intervalId = setInterval(async () => {
      const innerRes = await get();
      apiTriggerCount++;

      if (innerRes || apiTriggerCount >= 4) {
        clearInterval(intervalId);
        afterSuccess(innerRes ? 'success' : 'failed');
      }
    }, 5000);
  };

  const getIsCreditsPurchased = async (afterSuccess: () => void) => {
    const initialRes = await get();
    if (initialRes) {
      const initialCredits = initialRes.total_purchased_credits;

      let apiTriggerCount = 0;
      const intervalId = setInterval(async () => {
        const innerRes = await get();
        apiTriggerCount++;

        if (
          (innerRes && innerRes.total_purchased_credits !== initialCredits) ||
          apiTriggerCount >= 4
        ) {
          clearInterval(intervalId);
          afterSuccess();
        }
      }, 5000);
    }
  };

  return {
    isFetchingSubscription,
    isFetchingPlans,
    subscription,
    plans,
    addOnPlans,
    get,
    getPlans,
    getAddonPlans,
    getIsPlanPurchased,
    getIsCreditsPurchased,
  };
};
