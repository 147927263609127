import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { Notification } from 'domain/modules';
import { CreateProject } from 'presentation/modules/company/screens/createProject';
import { timeDisplay } from './helpers';
import { useSubscription } from '../../../application/modules/subscription/hooks/useSubscription';
import { useState, useEffect } from 'react';
import { NoSubscription } from 'presentation/components/KanbanBoard/components/NoSubscription';

interface IProps {
  onClose: () => void;
  isFetching: boolean;
  notifications: Array<Notification>;
  getAll: () => void;
  markAsRead: (value: string) => void;
}

export const NotificationsDrawer = ({
  onClose,
  isFetching,
  notifications,
  getAll,
  markAsRead,
}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getPlans, plans, isFetchingPlans, subscription, get } = useSubscription();

  useEffect(() => {
    getPlans();
    get();
  }, []);

  if (isFetching && notifications.length === 0) {
    return <p>Is Fetching notifications!!!</p>;
  }

  const handleSubscriptionClose = (action: string) => {
    console.log(`Subscription action: ${action}`);
    setIsModalOpen(false);
  };

  const onAction = (notification: Notification, shouldClose = true) => {
    if (!notification.isViewed) {
      markAsRead(notification.id);
      getAll();
    }
    shouldClose && onClose();
  };

  const renderActionButton = (notification: Notification) => {
    switch (notification.ctaLink) {
      case 'createproject':
        return (
          <CreateProject
            Trigger={({ open }) => {
              return (
                <Button
                  className="bg-black text-white rounded-full px-6 self-start"
                  size="sm"
                  onClick={() => {
                    open();
                    onAction(notification, false);
                  }}
                >
                  Create Project
                </Button>
              );
            }}
            onComplete={onClose}
          />
        );
      case 'subscription':
        if (subscription) return null;
        return (
          <>
            <Link
              to={'#'}
              onClick={() => {
                setIsModalOpen(true);
                onAction(notification, false);
              }}
            >
              <span className="text-xs hover:underline text-[#0F5ED6]">{'View Subsription'}</span>
            </Link>
            <NoSubscription
              isOpen={isModalOpen}
              plans={plans}
              isFetchingPlans={isFetchingPlans}
              onCloseSubscription={handleSubscriptionClose}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex gap-4 overflow-y-auto flex-wrap pb-4">
      {notifications.map((notification) => {
        return (
          <Card className="w-full border-1" shadow="none" key={notification.id}>
            <CardHeader className="justify-between">
              <p
                style={{ color: notification.typeColor || 'black' }}
                className="font-medium text-md flex items-center"
              >
                {notification.typeIcon === 'info' ? (
                  <i className="ri-information-2-fill mr-1" />
                ) : null}
                {notification.typeText}
              </p>
              <div className="flex items-center gap-4">
                <span className="text-xs">{timeDisplay(notification.created_at)}</span>
                {notification.isViewed ? null : (
                  <p className="h-[8px] w-[8px] rounded-full bg-sky-300" />
                )}
              </div>
            </CardHeader>
            <CardBody className="px-3 py-0 text-small text-default-400 flex flex-row">
              <div className="flex flex-col gap-3 justify-start pb-3 flex-1">
                <p
                  dangerouslySetInnerHTML={{ __html: notification.messagePattern }}
                  className="text-[#686868]"
                />
                {notification.ctaType === 'link' ? (
                  <Link to={notification.ctaLink} onClick={() => onAction(notification)}>
                    <span className="text-xs hover:underline text-[#0F5ED6]">
                      {notification.ctaText}
                    </span>
                  </Link>
                ) : null}
                {notification.ctaType === 'button' ? renderActionButton(notification) : null}
              </div>
              {notification.thumbnail_url ? (
                <div className="w-[60px] h-[60px] bg-gray-50">
                  <img
                    src={notification.thumbnail_url}
                    height="100%"
                    width="100%"
                    alt="Notification Thumbnail"
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        );
      })}
    </div>
  );
};
