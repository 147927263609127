import { Modal, ModalBody, ModalContent, Spinner } from '@nextui-org/react';

export const PaymentProcessing = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Modal isOpen={isOpen} size="xl" classNames={{ body: 'p-0 py-8 ' }} hideCloseButton>
      <ModalContent>
        {() => (
          <ModalBody>
            <div className="flex flex-col items-center justify-center gap-6">
              <Spinner color="default" size="lg" />
              <p className="text-[#828282] text-center font-medium w-4/5">
                Processing your payment...
              </p>
            </div>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
