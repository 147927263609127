import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  useDisclosure,
} from '@nextui-org/react';
import { Brand } from 'domain/modules/brand';
import { Button } from 'presentation/components';
import { ColorPicker } from './ColorPicker';
import { useNavigate } from 'react-router-dom';
import { BrandDeleteCard } from './BrandDeleteCard';

interface IProps {
  brand: Brand;
}

export const BrandCard = (props: IProps) => {
  const { brand } = props;
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const openBrand = () => {
    navigate(`/brand/${brand.id}`);
  };

  const editBrand = () => {
    navigate(`/brand/${brand.id}/edit`);
  };

  return (
    <>
      <div className="rounded-lg border p-3 pb-2">
        <div
          className="brand_card_color_pallette rounded border mb-2 truncate cursor-pointer"
          onClick={openBrand}
        >
          {(brand.colors || []).map((color, index) => (
            <ColorPicker color={color} key={index} />
          ))}
        </div>
        <div className="flex justify-between items-center">
          <p className="font-bold text-sm">{brand.name}</p>
          <Dropdown>
            <DropdownTrigger>
              <Button isIconOnly variant="light" size="sm">
                <i className="ri-more-fill"></i>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem key="open" onClick={openBrand}>
                Open
              </DropdownItem>
              <DropdownItem key="edit" onClick={editBrand}>
                Edit
              </DropdownItem>
              <DropdownItem
                key="delete"
                className="text-danger"
                color="danger"
                onClick={() => onOpen()}
              >
                Delete Brand Kit
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <BrandDeleteCard isOpen={isOpen} onOpenChange={onOpenChange} brand={brand} />
    </>
  );
};
