import './CommentStructure.scss';
import { useContext } from 'react';
import { GlobalContext } from '../../context/Provider';
import InputField from '../InputField/Index';
import '@szhsin/react-menu/dist/core.css';
import moment from 'moment';
import { Avatar } from '@nextui-org/react';

interface CommentStructureProps {
  info: {
    userId: string;
    comId: string;
    fullName: string;
    avatarUrl: string;
    text: string;
    userProfile?: string;
    updatedAt: string;
    replies?: Array<object> | undefined;
  };
  editMode: boolean;
  parentId?: string;
  replyMode: boolean;
  logIn: {
    loginLink: string;
    signupLink: string;
  };
}

const CommentStructure = ({ info, editMode, parentId, replyMode }: CommentStructureProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalStore: any = useContext(GlobalContext);
  const currentUser = globalStore.currentUserData;

  const replyBottomSection = () => {
    return (
      <div>
        <div className="flex items-start gap-2">
          <Avatar
            name={info.fullName.slice(0, 1) || 'A'}
            className="h-8 w-8 mr-2"
            color="primary"
            src={info.avatarUrl || info.userProfile}
            as="button"
            size="sm"
          />
          <div className="flex-1">
            {info.userId === currentUser.currentUserId ? (
              <h4>You</h4>
            ) : (
              <h4>{info.fullName || 'Admin'}</h4>
            )}

            <div className="flex justify-between items-start gap-2">
              <h5 className="comment text-sm flex-1">{info.text}</h5>
              {currentUser && !parentId && (
                <button
                  className="replyBtn"
                  onClick={() => globalStore.handleAction(info.comId, false)}
                >
                  <div className="replyIcon" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="ml-10 text-right">
          <p className="dateStyle">{moment(info.updatedAt).format('MMMM Do YYYY, HH:mm ')}</p>
        </div>
      </div>
    );
  };

  const actionModeSection = (mode: string) => {
    if (mode === 'reply') {
      return (
        <div className="replysection">
          {replyBottomSection()}
          <InputField
            formStyle={{
              backgroundColor: 'transparent',
              padding: '20px 0px',
              marginLeft: '-15px',
            }}
            comId={info.comId}
            fillerText={''}
            mode={'replyMode'}
            parentId={parentId}
          />
        </div>
      );
    } else {
      return (
        <InputField
          formStyle={{
            backgroundColor: 'transparent',
            padding: '20px 0px',
            marginLeft: '-15px',
          }}
          comId={info.comId}
          fillerText={info.text}
          mode={'editMode'}
          parentId={parentId}
        />
      );
    }
  };

  return (
    <div>
      {editMode
        ? actionModeSection('edit')
        : replyMode
        ? actionModeSection('reply')
        : replyBottomSection()}
    </div>
  );
};

export default CommentStructure;
