import React, { useEffect } from 'react';
import './ChatWrapper.css';
import { cn } from '@nextui-org/react';
import { GetEmail } from './EmailInputModal';
import { useAuth } from 'application/modules';
import { userService } from 'infra/api';
import { Subscription } from 'domain/modules';

const ChatWrapper: React.FC<{ subscription: Subscription | null; children: React.ReactNode }> = ({
  subscription,
  children,
}) => {
  const { user, beUser, getUser } = useAuth();
  useEffect(() => {
    getUser();
  }, []);
  const handleChatClick = () => {
    userService.getRedirect({ user_id: beUser?.id }).then((res) => {
      window.open(res);
    });
  };

  return (
    <div className="chat-wrapper">
      {children}
      {subscription && (
        <GetEmail
          id={user?.uid || ''}
          Trigger={({ open }) => (
            <div
              className="chat-icon"
              onClick={() => (beUser?.discordUserId ? handleChatClick() : open())}
            >
              <i className={cn('ri-discord-line')} style={{ fontSize: '50px' }} />
            </div>
          )}
        />
      )}
    </div>
  );
};

export default ChatWrapper;
