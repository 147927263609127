import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  query,
  QueryConstraint,
  DocumentData,
  where,
  WhereFilterOp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { app } from '../firebase/setup';

const db = getFirestore(app);

export const useFireStore = () => {
  const deleteFSDocument = (collectionName: string, id: string) =>
    deleteDoc(doc(db, collectionName, id));

  const setFSDocument = (collectionName: string, id: string, data: Record<string, any>) =>
    setDoc(doc(db, collectionName, id), data);

  const updateFSDocument = (collectionName: string, id: string, data: Record<string, any>) =>
    updateDoc(doc(db, collectionName, id), data);

  const listenToDocChanges = (
    collectionName: string,
    whereConfig: Array<{ field: string; operator: WhereFilterOp; value: any }>,
    callback: (data: Array<{ id: string } & DocumentData>) => void,
    changeTypes: Array<'added' | 'modified' | 'removed'> = ['added', 'modified', 'removed']
  ) => {
    const collectionRef = collection(db, collectionName);
    const constraints: QueryConstraint[] = whereConfig.map((condition) =>
      where(condition.field, condition.operator, condition.value)
    );
    const specificQuery = query(collectionRef, ...constraints);

    return onSnapshot(specificQuery, (snapshot) => {
      const changes = snapshot.docChanges().filter((change) => changeTypes.includes(change.type));
      const data = changes.map((change) => ({
        id: change.doc.id,
        ...change.doc.data(),
      }));
      callback(data);
    });
  };

  return {
    deleteFSDocument,
    setFSDocument,
    updateFSDocument,
    listenToDocChanges,
  };
};

// Utility to delete a Firestore document

// // Utility to query Firestore with specific conditions
// export const queryFSCollection = (
//   collectionName: string,
//   constraints: QueryConstraint[],
//   callback: (data: Array<{ id: string } & DocumentData>) => void
// ) => {
//   const collectionRef = collection(db, collectionName);
//   const specificQuery = query(collectionRef, ...constraints);

//   return onSnapshot(specificQuery, (snapshot) => {
//     const data = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     callback(data);
//   });
// };

// export const queryFSCollectionWithWhere = (
//   collectionName: string,
//   whereConfig: Array<{ field: string; operator: firebase.firestore.WhereFilterOp; value: any }>,
//   callback: (data: Array<{ id: string } & DocumentData>) => void
// ) => {
//   const collectionRef = collection(db, collectionName);

//   // Generate dynamic where constraints
//   const constraints: QueryConstraint[] = whereConfig.map((condition) =>
//     where(condition.field, condition.operator, condition.value)
//   );

//   const specificQuery = query(collectionRef, ...constraints);

//   return onSnapshot(specificQuery, (snapshot) => {
//     const data = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     callback(data);
//   });
// };

// Utility to get Firestore document reference
// export const getDocRef = (collectionName: string, id: string) => doc(db, collectionName, id);

// Exporting the Firestore database instance
// export { db };
