import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { StepFieldName, Steps, StepsKeys } from './wizard';
import { useEffect } from 'react';
import { useAuth } from 'application/modules';
import { useCompany } from 'application/modules/company';
import './style.css';
import { Company } from 'domain/modules';
import { cn, useDisclosure } from '@nextui-org/react';
import { LogoutModal } from 'presentation/components/LogoutModal';
import { OnboardingNavbar } from './components/Navbar';
import { useTabSync } from 'application/modules/handlers/useTabSync';

export const CompanySetupScreen = () => {
  const { step } = useParams();
  const { beUser, getUser } = useAuth();
  const { get, create, fetchLov, update, company, isFetching, companySetupLov } = useCompany();
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isupdating, onOpen: onupdating, onClose: oncompleted } = useDisclosure();
  const { broadcastTabSync } = useTabSync();

  useEffect(() => {
    if (beUser?.company_id && beUser?.company_step_number === 'complete') {
      navigate('/workboard');
      broadcastTabSync.syncNavigation('/workboard');
    } else if (step?.includes('step')) {
      const [url_step_no, last_step_no] = [
        Number(step.charAt(4)),
        Number(beUser?.company_step_number),
      ];
      if (url_step_no > last_step_no) {
        navigate(`/company/onboarding/step${last_step_no}`);
      }
    }
  }, [beUser, navigate]);

  useEffect(() => {
    if (!company && !isFetching) {
      if (beUser?.company_id) {
        get();
      } else {
        create();
      }
    }
  }, [company, isFetching, get, beUser?.company_id, create]);

  useEffect(() => {
    getUser();
  }, [company]);

  useEffect(() => {
    if (company && !isFetching && beUser && companySetupLov.length === 0) {
      fetchLov();
    }
  }, [company, isFetching, beUser, companySetupLov, fetchLov]);

  const updateData = async (company: Company, stepName: string) => {
    onupdating();
    await update(company, stepName);
    if (stepName !== 'complete') {
      oncompleted();
      navigate(`/company/onboarding/step${stepName}`);
      broadcastTabSync.syncNavigation(`/company/onboarding/step${stepName}`);
    }
  };

  const Component = Steps[step as StepsKeys];
  const fieldName = StepFieldName[step as StepsKeys];

  return (
    <div className={cn('company_screen overflow-hidden', 'py-2 px-5 md:py-7 md:px-8')}>
      <OnboardingNavbar
        avatarUrl={beUser?.profile_picture}
        userName={beUser?.name}
        email={beUser?.email}
        onLogout={onOpen}
      />

      {company && Component ? (
        <div className="pb-12 h-[calc(100%-4.5rem)]">
          <Component
            fieldName={fieldName}
            company={company}
            updateData={updateData}
            isupdating={isupdating}
          />
        </div>
      ) : (
        <Navigate to="/" />
      )}

      <LogoutModal isOpen={isOpen} onOpenChange={onOpenChange} />
    </div>
  );
};
