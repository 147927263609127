/* eslint-disable @typescript-eslint/ban-types */
import './InputField.scss';
import { useContext } from 'react';
import { GlobalContext } from '../../context/Provider';
import post_comment from '../../assets/post_comment.svg';
import { Avatar, Button, Input } from '@nextui-org/react';

interface RegularInputProps {
  formStyle?: object;
  comId?: string;
  mode?: string;
  customImg?: string;
  inputStyle?: object;
  cancelBtnStyle?: object;
  submitBtnStyle?: object;
  imgStyle?: object;
  imgDiv?: object;
  handleSubmit: Function;
  text: string;
  setText: Function;
}

const RegularInput = ({ customImg, mode, handleSubmit, text, setText }: RegularInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalStore: any = useContext(GlobalContext);
  const PostIcon = () => <img src={post_comment} alt="post_comment" />;

  return (
    <form
      className="flex items-center justify-center gap-1 mb-4"
      onSubmit={(e) => {
        e.preventDefault();
        if (text) handleSubmit(e);
      }}
    >
      {/* <div className="userImg">
        <a target="_blank" href={globalStore.currentUserData.currentUserProfile} rel="noreferrer">
          <img
            src={globalStore.customImg || customImg || globalStore.currentUserData.currentUserImg}
            alt="userIcon"
            className="imgdefault"
          />
        </a>
      </div> */}
      <Avatar
        as="button"
        className="h-8 w-8 transition-transform"
        color="primary"
        name={globalStore.currentUserData?.currentUserFullName?.slice(0, 1) || 'A'}
        size="sm"
        src={globalStore.customImg || customImg || globalStore.currentUserData.currentUserImg}
      />

      <Input
        variant="bordered"
        size="sm"
        type="text"
        placeholder={mode === 'replyMode' ? 'Add your reply' : 'Add your comment'}
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="shadow-none bg-white w-[90%]"
        classNames={{
          inputWrapper: 'border border-solid border-[#091e4224]',
          input: !text ? 'text-[#757575] text-xs' : '',
          innerWrapper: 'pl-3',
        }}
        isClearable
        onClear={() => setText('')}
        startContent={
          mode ? (
            <p className="text-[232323] text-xs w-24">
              @{globalStore.currentUserData.currentUserFullName}
            </p>
          ) : null
        }
      />

      <Button
        isIconOnly
        type="submit"
        disabled={text != '' ? false : true}
        onClick={(e) => (text ? handleSubmit(e) : null)}
        className="bg-[#0D1216] rounded-full cursor-pointer"
      >
        <PostIcon />
      </Button>
    </form>
  );
};

export default RegularInput;
