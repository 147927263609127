import { useCompany } from 'application/modules/company';
import { WizardContainer } from '../components/WizardContainer';
import { Button } from 'presentation/components';
import { Company } from 'domain/modules';
import { useEffect, useState } from 'react';
import { Input, Radio, RadioGroup, cn } from '@nextui-org/react';

interface IProps {
  fieldName: string;
  company: Company;
  updateData: (company: Company, stepName: string) => void;
  isupdating: boolean;
}

export const Step1 = ({ fieldName, updateData, company, isupdating }: IProps) => {
  const [selectedValue, setSelectedValue] = useState(company.picasso_purpose);
  const [otherText, setOtherText] = useState('');
  const { getLovDataForField } = useCompany();
  const optionData = getLovDataForField(fieldName);

  useEffect(() => {
    if (
      company.picasso_purpose &&
      !company.picasso_purpose.includes('work') &&
      !company.picasso_purpose.includes('freelance') &&
      !company.picasso_purpose.includes('hobbyOrFun')
    ) {
      setSelectedValue('other');
      setOtherText(company.picasso_purpose);
    }
  }, []);

  const onNext = () => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { id, userId, ...rest } = company;
    updateData(
      {
        ...rest,
        picasso_purpose: selectedValue === 'other' ? otherText : selectedValue,
      },
      '2'
    );
    // navigate(`/company/onboarding/step${step}`);
  };

  const onChange = (value: string) => {
    if (value !== 'other') {
      setOtherText('');
    }
    setSelectedValue(value);
  };

  return (
    <WizardContainer
      title="What will you use Picassofusion for?"
      step={1}
      disableNavigate={!selectedValue || (selectedValue === 'other' && otherText.length === 0)}
      company={company}
    >
      <div className="w-full flex justify-center">
        <div>
          <RadioGroup onChange={(ev) => onChange(ev.target.value)} value={selectedValue}>
            {optionData.map((option) => {
              return (
                <Radio
                  key={option.id}
                  value={option.value}
                  className={cn(
                    'bg-white max-w-none rounded-[20px] text-center',
                    'border border-solid border-[#D9D9D9]',
                    'px-5 py-3 m-0 mb-2'
                  )}
                  classNames={{
                    base: 'w-80 md:w-[540px] min-h-14',
                    label: 'ml-4 text-black text-sm',
                    wrapper: 'border-[#D9D9D9] bg-transparent data-[selected]:border-[#D9D9D9]',
                    control: 'bg-black border-white',
                  }}
                >
                  {option.label}
                </Radio>
              );
            })}
          </RadioGroup>
          {selectedValue === 'other' ? (
            <Input
              size="sm"
              type="text"
              variant="bordered"
              isClearable
              value={otherText}
              onChange={(ev) => {
                setOtherText(ev.target.value);
              }}
              onClear={() => setOtherText('')}
              fullWidth
              className="my-2"
              classNames={{
                base: 'min-h-14',
                inputWrapper: cn(
                  'data-[focus]:border data-[focus]:border-[white] data-[focus]:border-solid',
                  'rounded-[20px] min-h-14 shadow-none'
                ),
              }}
              errorMessage={otherText.length > 40 && 'Maximum number of characters is 40'}
            />
          ) : null}
          <Button
            size="lg"
            className={cn('bg-[#99CF63] rounded-full', 'mt-2')}
            fullWidth
            disabled={isupdating}
            isLoading={isupdating}
            onClick={onNext}
            isDisabled={
              !selectedValue ||
              (selectedValue === 'other' && (otherText.length === 0 || otherText.length > 40))
            }
            endContent={<i className="ri-arrow-right-line" />}
          >
            Next
          </Button>
        </div>
      </div>
    </WizardContainer>
  );
};
