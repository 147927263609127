import { Button, CircularProgress, Input, cn } from '@nextui-org/react';
import { WizardFooter } from '../components/WizardFooter';
import { useState } from 'react';
import { useAssetsUpload } from 'application/shared';
import { FileUploader } from 'react-drag-drop-files';
import { AssetUploadResponse } from 'infra/api';
import { LeftPanel } from '../components/LeftPanel';
import { Project } from 'domain/modules';
import { CreateProjectSteps } from '../Wizard';
import { useProjectDetail } from 'application/modules/projects';
import { CreateProjectHeader } from '../components/Header';
import delete_icon from '../../../../../assets/delete_icon.svg';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { supportedProjectFileTypes } from 'presentation/constants';
import { FileRenderer } from 'presentation/components/FileRenderer';
import { LOGO_CATEGORY_ID } from '../constants';

interface FileData {
  id?: string;
  media_url: string;
  assetId: string;
}

interface IProps {
  project?: Project;
  isFetching: boolean;
  onChangeStep: (stepName: CreateProjectSteps, projectId?: string) => void;
  setIsApiPending?: (arg: boolean) => void;
  isApiPending?: boolean;
}

export const AdditionalInputs = (props: IProps) => {
  const { setIsApiPending = () => null } = props;

  const [files, setFiles] = useState<FileData[]>(
    props.project &&
      props.project.project_media_reference &&
      Array.isArray(props.project.project_media_reference)
      ? props.project.project_media_reference
      : []
  );
  const [voices, setVoices] = useState<FileData[]>(
    props.project &&
      props.project.project_voice_note &&
      Array.isArray(props.project.project_voice_note)
      ? props.project.project_voice_note
      : []
  );
  const [linkFields, setLinkFields] = useState<string[]>(
    props.project &&
      props.project.reference_links &&
      Array.isArray(props.project.reference_links.links)
      ? props.project.reference_links.links
      : ['']
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { updateProject, updateProjectStatus } = useProjectDetail();
  const { deleteAsset, upload } = useAssetsUpload();

  const onPrev = () => {
    props.onChangeStep('descriptionAndNarration');
  };

  const handleCreditUpdate = async () => {
    if (props.project) {
      await updateProjectStatus(props.project, 'TODO');
    }
    props.onChangeStep('completed');
  };

  const validateUrls = () => {
    const validUrls: string[] = [];
    const invalidUrls: string[] = [];

    linkFields.forEach((link) => {
      if (isUrlValid(link)) {
        validUrls.push(link);
      } else {
        invalidUrls.push(link);
      }
    });

    return invalidUrls.length === 0 ? true : false;
  };

  const onCreate = async (skip = false) => {
    setShowErrorMessage(true);
    if (skip) {
      handleCreditUpdate();
    } else {
      if (props.project && validateUrls()) {
        const tempProject = { ...props.project };
        if (files.length > 0) {
          tempProject.project_media_reference = files.map((file) => ({
            assetId: file.assetId,
            media_url: file.media_url,
            id: file.id ? file.id : undefined,
          }));
        }
        if (voices.length > 0) {
          tempProject.project_voice_note = voices.map((voice) => ({
            assetId: voice.assetId,
            media_url: voice.media_url,
            id: voice.id ? voice.id : undefined,
          }));
        }
        tempProject.reference_links = { links: linkFields };
        setIsApiPending(true);
        const data = await updateProject(tempProject);
        if (data) {
          handleCreditUpdate();
        }
      }
    }
  };

  const handleSaveUploadFile = async (asset: AssetUploadResponse) => {
    setFiles([
      ...files,
      {
        media_url: asset.url,
        assetId: asset.id,
      },
    ]);
  };

  const deleteFile = async (file: FileData) => {
    if (file.assetId) {
      await deleteAsset(file.assetId);
      const tempFiles = [...files].filter((i) => i.assetId !== file.assetId);
      setFiles(tempFiles);
    }
  };

  const addLinkField = () => {
    setLinkFields([...linkFields, '']);
  };

  const handleLinkChange = (index: number, value: string) => {
    const updatedLinkFields = [...linkFields];
    updatedLinkFields[index] = value;
    setLinkFields(updatedLinkFields);
  };

  const deleteLinkField = (index: number) => {
    const updatedLinkFields = [...linkFields];
    updatedLinkFields.splice(index, 1);
    setLinkFields(updatedLinkFields);
  };

  const addAudioElement = async (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    const filePath = await upload(
      `project/${props.project?.id}/additionalInputs/voice_notes`,
      new File([blob], `voice_note_${new Date().getTime()}.webm`)
    );
    handleVoiceUploads(filePath);
  };

  const deleteVoiceNote = async (file: FileData) => {
    if (file.assetId) {
      await deleteAsset(file.assetId);
      const tempFiles = [...voices].filter((i) => i.assetId !== file.assetId);
      setVoices(tempFiles);
    }
  };

  const handleVoiceUploads = async (asset: AssetUploadResponse) => {
    setVoices([
      ...voices,
      {
        media_url: asset.url,
        assetId: asset.id,
      },
    ]);
  };

  const isUrlValid = (string: string) => {
    if (string) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
        'i'
      );
      return pattern.test(string);
    } else {
      return true;
    }
  };

  return (
    <LeftPanel>
      <>
        <CreateProjectHeader
          title="Create Design Task"
          totalSteps={props.project?.category_id === LOGO_CATEGORY_ID ? 9 : 5}
          currentStep={props.project?.category_id === LOGO_CATEGORY_ID ? 9 : 5}
        />
        <div className="grow flex flex-col gap-4">
          <div className="file_upload">
            <h3 className="text-[#232323] font-semibold text-base 2xl:text-xl mb-2">
              Would you like to add anything?
            </h3>

            <div className={files.length > 0 ? 'flex items-center flex-wrap gap-4 mb-4' : 'w-full'}>
              <FileRenderer files={files} deleteFile={deleteFile} />
              <FileUpload
                hasUploadedFiles={files.length === 0}
                path={`project/${props.project?.id}/additionalInputs`}
                handleSaveUploadFile={handleSaveUploadFile}
                length={files.length}
              />
            </div>
          </div>

          <div className="voice_upload">
            <div className="mb-3">
              <h3 className="font-semibold text-base 2xl:text-xl mb-1">
                How can we understand your design preferences?
              </h3>
              <p className={cn('text-[#626F86] text-xs italic 2xl:text-medium')}>
                Share a voice note with your design cues to help us master your design needs.
              </p>
            </div>

            {voices.length > 0 ? null : (
              <AudioRecorder
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                downloadFileExtension="webm"
                showVisualizer
              />
            )}
          </div>

          <div id="audio-renderer" className="w-full">
            {voices.map((voice) => (
              <div key={voice.assetId} className="flex items-center">
                <audio controls preload="auto">
                  <source src={voice.media_url} type="audio/mpeg"></source>
                  <source src={voice.media_url} type="audio/ogg"></source>
                </audio>
                <Button
                  variant="light"
                  color="danger"
                  onClick={() => deleteVoiceNote(voice)}
                  className="data-[hover]:bg-transparent text-sm font-medium"
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>

          <div className="grow flex flex-col">
            {linkFields.map((value, index) => (
              <Input
                variant="bordered"
                key={index}
                type="text"
                label={index === 0 ? 'Do you have any additional links to include?' : ''}
                placeholder="Enter the URL"
                labelPlacement="outside"
                value={value}
                onChange={(e) => handleLinkChange(index, e.target.value)}
                endContent={
                  <Button
                    isIconOnly
                    onClick={() => {
                      deleteLinkField(index);
                    }}
                    variant="light"
                    className="p-0 m-0"
                    isDisabled={index === 0}
                  >
                    <img src={delete_icon} alt="delete_icon" className="cursor-pointer" />
                  </Button>
                }
                classNames={{
                  label: 'text-[#CA0B4A] leading-[22px] font-medium 2xl:text-xl',
                  input: !value ? 'text-xs 2xl:text-medium' : '',
                  inputWrapper: cn(
                    'rounded-[16px] min-h-[48px] shadow-none',
                    showErrorMessage && value && !isUrlValid(value)
                      ? 'border border-solid border-[red]'
                      : '',
                    'data-[focus=true]:border-default'
                  ),
                  innerWrapper: 'pl-3',
                  errorMessage: 'text-right',
                }}
                className="mb-1"
                errorMessage={showErrorMessage && value && !isUrlValid(value) && 'Not a valid link'}
              />
            ))}

            <div className="flex justify-end">
              <Button
                size="sm"
                variant="light"
                className={cn(
                  'p-0 text-black font-medium text-[16px]',
                  'data-[hover]:bg-transparent'
                )}
                onPress={addLinkField}
                isDisabled={
                  !isUrlValid(linkFields[linkFields.length - 1]) ||
                  linkFields[linkFields.length - 1].length === 0
                }
              >
                + Add
              </Button>
            </div>
          </div>
        </div>
        <WizardFooter
          onPrev={onPrev}
          onNext={() => onCreate()}
          disclaimerText="*Delivery time and design credits utilized are determined by the options you've selected."
          isLastStep
          processing={props.isApiPending}
        />
      </>
    </LeftPanel>
  );
};

interface IFileUploadProps {
  hasUploadedFiles: boolean;
  handleSaveUploadFile: (asset: AssetUploadResponse) => void;
  path: string;
  length: number;
}

const FileUpload = ({ hasUploadedFiles, handleSaveUploadFile, path, length }: IFileUploadProps) => {
  const [loading, setIsLoading] = useState(false);
  const { upload } = useAssetsUpload();

  const handleChange = async (file: File) => {
    try {
      setIsLoading(true);
      const filePath = await upload(path, file);
      handleSaveUploadFile(filePath);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  if (length >= 10) {
    return null;
  }

  return (
    <FileUploader handleChange={handleChange} name="file" types={supportedProjectFileTypes}>
      {hasUploadedFiles ? (
        <div
          className={cn(
            'bg-[#F9F9F9] rounded-lg py-3',
            'flex flex-col justify-center items-center gap-1',
            'focus:outline-none '
          )}
        >
          <i className="ri-upload-cloud-2-line ri-lg mb-0.5" />
          {loading ? (
            <p className="font-medium text-sm flex gap-4 items-center">
              <CircularProgress aria-label="Loading..." size="sm" /> Uploading
            </p>
          ) : (
            <div className="flex flex-col items-center gap-0.5">
              <p className="text-[#0D1216] text-xs">
                <span className="text-[#136DC7] cursor-pointer">Click here</span> to upload or drop
                media here
              </p>
              <p className={cn('text-[#232323] text-[10px] italic 2xl:text-medium')}>
                You can add up to 10 files in formats like .png, .svg, .jpeg, .pdf, .mp4, .doc, .ppt, .mp3, .pptx)
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          className={cn(
            'bg-[#40576d12]',
            'w-20 h-20',
            'rounded border flex justify-center items-center hover:bg-gray-100 cursor-pointer'
          )}
        >
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <div className="text-center">
              <i className="ri-upload-cloud-2-line" />
              <p className="text-[#0D1216] text-sm">Add new</p>
            </div>
          )}
        </div>
      )}
    </FileUploader>
  );
};
