import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/react';
import { useLocation, useNavigate } from 'react-router-dom';

export const DiscordComplete = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invite_code = new URLSearchParams(location.search)?.get('invite_uri');

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-6xl font-bold mb-6">Successful!</h1>
      <h2 className="text-2xl font-bold mb-6">Discord Connected</h2>
      <div className="flex gap-5 mt-2 w-[50%] flex-wrap justify-center">
        <Button
          size="lg"
          onClick={() => navigate('/workboard')}
          radius="full"
          startContent={<i className="ri-arrow-left-line" />}
          className={cn('bg-[#F0F0F0]', 'text-black font-medium min-width-[200px]')}
        >
          Go to Home Page
        </Button>
        <Button
          size="lg"
          radius="full"
          className={cn('bg-[#99CF63] min-width-[200px]')}
          onClick={() => window.open(invite_code || '', '_blank', 'noopener,noreferrer')}
          endContent={<i className="ri-arrow-right-line" />}
        >
          Connect to server
        </Button>
      </div>
    </div>
  );
};
