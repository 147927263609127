import { useCompany } from 'application/modules/company';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'presentation/components';
import { Company } from 'domain/modules';
import { useState } from 'react';
import { CheckboxGroup, cn } from '@nextui-org/react';
import { CustomServiceSelection } from '../components/CustomServiceSelection';
import { TOTAL_WIZARD_STEPS } from './index';

interface IProps {
  fieldName: string;
  company: Company;
  updateData: (company: Company, stepName: string) => void;
  isupdating: boolean;
}

export const Step4 = ({ fieldName, company, updateData, isupdating }: IProps) => {
  const [selectedValue, setSelectedValue] = useState((company.picasso_services || '').split(','));
  const { getLovDataForField } = useCompany();
  const optionData = getLovDataForField(fieldName);
  const navigate = useNavigate();
  const stepno = useParams();

  const onNext = () => {
    updateData(
      {
        ...company,
        picasso_services: selectedValue.join(','),
      },
      'complete'
    );
  };

  const onChange = (selectedOptions: string[]) => {
    setSelectedValue(selectedOptions);
  };

  const onBack = (step: number) => {
    navigate(`/company/onboarding/step${step}`);
  };

  return (
    <div className="h-full w-full flex flex-col justify-center items-center">
      <div className="mb-4 flex items-end justify-between gap-6">
        <div>
          <h1
            className={cn(
              'text-[#232323] font-bold',
              'text-lg md:text-2xl xl:text-2xl',
              'mb-3 md:mb-2'
            )}
          >
            How do you want to use Picassofusion?
          </h1>

          <h4 className={cn('text-[#232323] text-sm')}>Choose upto 3 interests</h4>
        </div>

        <div className="bg-[#F0F0F0] rounded-full p-2 md:p-4">
          <p className="text-black text-xs">
            {stepno.step?.slice(4)}/{TOTAL_WIZARD_STEPS}
          </p>
        </div>
      </div>

      <div
        className={cn(
          'w-content',
          'w-full xl:w-[80%] 2xl:w-[1240px]',
          'flex flex-col items-center justify-center  gap-[52px] '
        )}
      >
        <CheckboxGroup
          className="gap-1"
          orientation="horizontal"
          classNames={{
            wrapper: cn(
              'center flex items-center justify-center ',
              'gap-y-4 md:gap-y-6 gap-x-4 md:gap-x-8'
            ),
          }}
          value={selectedValue}
          onChange={(ev) => {
            if (Array.isArray(ev)) {
              const _tempData = ev.filter((i) => i);
              if (_tempData.length <= 3) {
                onChange(_tempData as string[]);
              }
            }
          }}
        >
          {optionData.map((option) => {
            return (
              <CustomServiceSelection key={option.id} value={option.value} label={option.label} />
            );
          })}
        </CheckboxGroup>

        <div className="flex gap-5 mt-3 items-center justify-center">
          <Button
            size="lg"
            fullWidth
            onClick={() => onBack(3)}
            radius="full"
            startContent={<i className="ri-arrow-left-line" />}
            className={cn(
              'bg-[#F0F0F0]',
              'text-black font-medium',
              'md:w-[200px] lg:w-[220px] xl:w-[260px]'
            )}
          >
            Back
          </Button>
          <Button
            size="lg"
            fullWidth
            radius="full"
            className={cn(
              'bg-[#99CF63]',
              'disabled:opacity-100 disabled:text-gray-400',
              'md:w-[200px] lg:w-[220px] xl:w-[260px]'
            )}
            onClick={onNext}
            isDisabled={
              !Array.isArray(selectedValue) ||
              selectedValue.filter((i) => i).length === 0 ||
              selectedValue.filter((i) => i).length > 3 ||
              isupdating
            }
            isLoading={isupdating}
            endContent={<i className="ri-arrow-right-line" />}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
