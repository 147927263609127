import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react';
import { Plan } from 'domain/modules/subscription/models/plans.model';
import { PlanList } from './PlanList';

interface IProps {
  isOpen: boolean;
  plans: Plan[];
  isFetchingPlans: boolean;
  onCloseSubscription: (arg0: string) => void;
}

export const NoSubscription = ({ isOpen, plans, onCloseSubscription, isFetchingPlans }: IProps) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        isDismissable={false}
        hideCloseButton
        size="5xl"
        scrollBehavior="normal"
        placement="center"
        classNames={{
          base: 'w-[90vw] md:w-[80%] !rounded-xl h-auto',
          body: 'px-0 md:px-6',
        }}
      >
        <ModalContent>
          <>
            <ModalHeader className="flex items-center justify-between flex-col gap-4 md:flex-row md:gap-2">
              <div className="flex justify-between items-center w-full md:w-[40%]">
                <h3 className=" text-black font-semibold text-2xl md:text-4xl md:font-bold">
                  Choose a Plan
                </h3>
                <Button
                  variant="light"
                  className="text-[#222] text-[16px] py-2 px-4 data-[hover]:bg-transparent block md:hidden"
                  onPress={() => onCloseSubscription('skip')}
                >
                  Skip
                </Button>
              </div>
              <p className=" text-[#828282] text-[16px] font-medium leading-[18px] w-full text-left hidden md:w-[80%] md:block">
                Select the plan that aligns with your design goals. Whether you&apos;re a business
                looking for frequent designs or an individual with a one-time project, we have
                options to suit your needs. Each plan offers flexible credits, giving you control
                over your design process. Pick the plan that fits your budget and scale your
                creativity as you grow!
              </p>
            </ModalHeader>
            <ModalBody className="pb-4 gap-0">
              <PlanList
                plans={plans}
                isFetchingPlans={isFetchingPlans}
                onCloseSubscription={onCloseSubscription}
              />
            </ModalBody>
            <ModalFooter className="flex-row-reverse items-center justify-between hidden md:flex py-0">
              <Button
                variant="light"
                className="text-[#222] text-[16px] py-2 px-4 data-[hover]:bg-transparent"
                onPress={() => onCloseSubscription('skip')}
              >
                Skip for now
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};
