import { NextUIProvider } from '@nextui-org/react';
import { ReduxProvider } from 'application/shared';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { RootRoute } from 'presentation/routes/RootRoute';
import { ToastProvider, useToast } from './presentation/components/CustomAlert';
import { useEffect } from 'react';

axios.defaults.baseURL = import.meta.env.VITE_API_HOST;

const CustomAlert: React.FC = () => {
  const { addToast } = useToast();

  useEffect(() => {
    // Replace global alert
    window.alert = (message: string, title?: 'Alert' | 'Error' | 'Success') => {
      addToast(title || 'Alert', message);
    };
  }, [addToast]);

  return null;
};

// Deploy
function App() {
  return (
    <div className="App h-full">
      <ReduxProvider>
        <NextUIProvider className="h-full">
          <ToastProvider>
            <CustomAlert />
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              maxSnack={3}
            >
              <RootRoute />
            </SnackbarProvider>
          </ToastProvider>
        </NextUIProvider>
      </ReduxProvider>
    </div>
  );
}

export default App;
