import { Button } from '@nextui-org/button';
import { useProjectDetailContext } from '../../context';
import { RequestIteration } from '../../../projectDetail/components/RequestIteration';
import { useFireStore } from 'infra/providers/firestore';
import { COLLECTION_CONFIG } from 'infra/providers/firestore/constants';

export const FooterMarkAsComplete = () => {
  const { project, refetchProject, updateStatus, hasARequestedIteration } =
    useProjectDetailContext();
  const { deleteFSDocument } = useFireStore();

  return (
    <Button
      className="rounded-full border-1 border-black"
      variant="bordered"
      size="sm"
      startContent={<i className="ri-check-line ri-xl" />}
      isDisabled={project.status !== 'IN_REVIEW' || hasARequestedIteration()}
      onClick={async () => {
        const data = await updateStatus(project, 'COMPLETED');
        await deleteFSDocument(COLLECTION_CONFIG.PROJECT_STATUS_COLLECTION, project.id);
        console.log(`Project ${project.id} deleted.`);
        if (data) refetchProject();
      }}
    >
      Mark as Completed
    </Button>
  );
};

export const FooterIteractionRequest = () => {
  const { project, refetchProject } = useProjectDetailContext();

  if (project.status !== 'IN_REVIEW') {
    return null;
  }

  return (
    <RequestIteration projectId={project.id} project={project} refetchProject={refetchProject} />
  );
};
