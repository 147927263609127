import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabSyncService as BroadcastTabSyncService } from 'application/modules/handlers/broadcast/tabSync';
import { TabSyncService as PostMessageTabSyncService } from 'application/modules/handlers/postmessage/tabSync';

export function useTabSync() {
  const navigate = useNavigate();
  const broadcastTabSync = BroadcastTabSyncService.getInstance();
  const postMessageTabSync = PostMessageTabSyncService.getInstance();

  useEffect(() => {
    const handleNavigation = (event: CustomEvent<string>) => {
      navigate(event.detail);
    };

    window.addEventListener('app:navigation', handleNavigation as EventListener);

    return () => {
      window.removeEventListener('app:navigation', handleNavigation as EventListener);
    };
  }, [navigate]);

  return { broadcastTabSync, postMessageTabSync };
}
