import { Header } from './header';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { Card, CardBody, cn, Tooltip } from '@nextui-org/react';
import { useProjectDeliverables } from 'application/modules/projects/hooks/useProjectDeliverables';
import { Button } from 'presentation/components';
import folderImage from 'presentation/assets/folder.png';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';

const folderMapper = {
  DESIGN_FILE: 'Design Files',
  SOURCE_FILE: 'Source Files',

  SHOWCASE_FILES_THUMBNAIL: 'Thumbnail',
  SOURCE_FILES_THUMBNAIL: 'Thumbnail',
  MOCKUP_FILES_THUMBNAIL: 'Thumbnail',

  SHOWCASE_FILES: 'Showcase Files',
  MOCKUP_FILES: 'Mockup Files',

  OTHER_FILES: 'Other Files',
  Q_AND_C: 'Q&C',
  THUMBNAIL: 'Thumbnail',
};

export const ListingsByProject = () => {
  const { projectId, iterationId } = useParams();
  const { getAttachments, deliveryAttachments, isFetching } = useProjectDeliverables();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const selectedFolderType: keyof typeof folderMapper | null = new URLSearchParams(
    window.location.search
  ).get('type') as keyof typeof folderMapper | null;

  const handleDownload = useCallback((img: string) => {
    const anchor = document.createElement('a');
    anchor.href = img;
    anchor.download = img;
    anchor.click();
    document.body.removeChild(anchor);
  }, []);

  useEffect(() => {
    if (projectId && iterationId) {
      getAttachments(projectId, iterationId);
    }
  }, [projectId, iterationId]);

  const pageTitle = useMemo(() => {
    const label = state && state.projectTitle;
    return `${decodeURIComponent(label)} ${
      selectedFolderType ? `/${folderMapper[selectedFolderType]}` : ''
    }`;
  }, [state, selectedFolderType]);

  const getImageByFileType = useCallback((url: string) => {
    const _url = url.toLowerCase();
    const commonClassNames = 'text-9xl text-gray-500';

    if (_url.includes('.zip')) {
      return <i className={cn('ri-file-zip-fill', commonClassNames)} />;
    }

    if (_url.includes('.mp3')) {
      return <i className={cn('ri-file-music-fill', commonClassNames)} />;
    }
    if (_url.includes('.mp4')) {
      return <i className={cn('ri-file-video-fill', commonClassNames)} />;
    }
    if (_url.includes('.jpg') || _url.includes('.jpeg') || _url.includes('.png')) {
      return (
        <img src={_url} alt="preview" className={cn('ri-file-image-fill', commonClassNames)} />
      );
    }
    if (_url.includes('.pdf')) {
      return <i className={cn('ri-file-pdf-2-fill', commonClassNames)} />;
    }
    if (_url.includes('.ppt') || _url.includes('.pptx')) {
      return <i className={cn('ri-file-ppt-fill', commonClassNames)} />;
    }
    if (_url.includes('.docx') || _url.includes('.doc')) {
      return <i className={cn('ri-file-word-fill', commonClassNames)} />;
    }
    if (_url.includes('.xls') || _url.includes('.xlsx')) {
      return <i className={cn('ri-file-excel-fill', commonClassNames)} />;
    }
    return <i className={cn('ri-file-3-fill', commonClassNames)} />;
  }, []);

  const filteredProjects = useMemo(() => {
    if (selectedFolderType === 'THUMBNAIL') {
      return deliveryAttachments.filter(({ project_asset_type }) =>
        project_asset_type.includes('THUMBNAIL')
      );
    } else {
      return deliveryAttachments.filter(
        ({ project_asset_type }) => project_asset_type === selectedFolderType
      );
    }
  }, [selectedFolderType]);

  const getFilesForFolders = useMemo(
    () =>
      filteredProjects.map((deliverables) => {
        return (
          <div key={deliverables.id} className="">
            <Card
              radius="lg"
              className="border-none h-[250px] w-[250px] flex flex-col justify-between"
            >
              <div className="flex justify-center items-center overflow-hidden h-[90%]">
                {getImageByFileType(deliverables.asset.url)}
              </div>

              <Tooltip showArrow={true} content={deliverables.name}>
                <div className="flex items-center justify-between px-5">
                  <p className="line-clamp-1 text-sm">{deliverables.name}</p>

                  <Button
                    isIconOnly
                    variant="light"
                    className="p-0"
                    onClick={() => handleDownload(deliverables.asset.url)}
                    isDisabled={deliverables.project_asset_type === 'SOURCE_FILE'}
                  >
                    <i className="ri-download-2-line" />
                  </Button>
                </div>
              </Tooltip>
            </Card>
          </div>
        );
      }),
    [filteredProjects]
  );

  const foldersToShow = useMemo(() => {
    const thumbnail = [];
    const others = new Set();

    deliveryAttachments.forEach((file) => {
      if (file.project_asset_type.includes('THUMBNAIL')) {
        thumbnail.push('THUMBNAIL');
      } else {
        others.add(file.project_asset_type);
      }
    });

    return thumbnail.length > 0 ? ['THUMBNAIL', ...others] : [...others];
  }, [deliveryAttachments]);

  if (isFetching) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '80vh',
        }}
      />
    );
  } else if (deliveryAttachments.length === 0) {
    return (
      <>
        <Header title={pageTitle} />
        <div className="flex gap-6 mt-4">
          <span className="text-xs italic px-4 py-5">
            No attachments uploaded for this iteration
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <Header title={pageTitle} />
      <div className="flex gap-6 mt-4 flex-wrap">
        {selectedFolderType
          ? getFilesForFolders
          : foldersToShow.map((folder) => (
              <Card
                className={cn('border-none', 'py-1 px-3 w-[300px]')}
                radius="lg"
                key={folder as string}
              >
                <CardBody
                  className="justify-between cursor-pointer"
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.set('type', folder as string);
                    navigate(`${url.pathname}?${url.searchParams.toString()}`, { state });
                  }}
                >
                  <div className="flex gap-4">
                    <LazyImage
                      alt={folder}
                      className="object-cover h-[60px] w-[60px]"
                      src={folderImage}
                      width="100%"
                    />
                    <div className="flex flex-col gap-2 items-start justify-center">
                      <h4 className={cn('text-black text-xl font-semibold', 'text-center')}>
                        {folderMapper[folder as keyof typeof folderMapper]}
                      </h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
      </div>
    </>
  );
};
