import {
  Button,
  cn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  useDisclosure,
} from '@nextui-org/react';
import { displayProjectStatus } from '../common/helpers';
import { useProjectDetailContext } from '../../context';
import { useEffect, useMemo } from 'react';
import { columnList, STATE_TRANSITIONS } from 'application/modules/projects/constants';
import { IColumn } from 'application/modules/projects/types';
import { canTransition } from 'application/modules/projects/utils';
import { useWorkboard } from 'application/modules/projects';
import { useSubscription } from 'application/modules/subscription';
import { InsufficientCredits } from 'presentation/components/KanbanBoard/components/InsufficientCredits';
import { IterationStatusChip } from '../../../projectDetail/components/IterationStatusChip';
import { CreateProjectCustomTrigger } from '../../../createProject';
import { useLocation, useNavigate } from 'react-router-dom';

export const HeaderIterationAction = () => {
  const { project } = useProjectDetailContext();
  return <IterationStatusChip project={project} />;
};

export const HeaderStatusAction = () => {
  const { projectsList } = useWorkboard();
  const { project, refetchProject, updateStatus } = useProjectDetailContext();
  const { get, subscription } = useSubscription();
  const {
    isOpen: creditsModalIsOpen,
    onOpen: creditsModalOnOpen,
    onClose: creditsModalOnClose,
    onOpenChange: creditsModalOnOpenChange,
  } = useDisclosure();

  const supportedStatus = useMemo(() => {
    if (project) {
      const transistions = STATE_TRANSITIONS[project?.status] || [];
      return transistions.map((t) => columnList.find((c) => c.id === t));
    }
    return [];
  }, [project]);

  const isCreditsExhausted = () => {
    if (subscription && project) {
      const totalCredits = subscription.total_purchased_credits;
      const usedCredits = subscription.used_credits;

      return totalCredits - usedCredits < project.credits ? true : false;
    } else {
      return false;
    }
  };

  const updateProjectStatus = async (status: IColumn | undefined) => {
    const projectsInLine = projectsList.filter(
      (project) =>
        project.status === 'START_DESIGNING' ||
        project.status === 'IN_PROGRESS' ||
        project.status === 'IN_REVIEW'
    );

    if (project && status && status.id !== project.status) {
      if (status.id === 'START_DESIGNING' && projectsInLine.length > 0) {
        alert(`Couldn't move current project to next state`);
      } else if (status.id === 'START_DESIGNING' && isCreditsExhausted()) {
        creditsModalOnOpen();
      } else {
        if (canTransition(project.status, status.id)) {
          const data = await updateStatus(project, status.id);
          if (data) {
            refetchProject();
          }
        }
      }
    }
  };

  if (['TODO', 'START_DESIGNING'].includes(project.status)) {
    return (
      <>
        <Dropdown>
          <DropdownTrigger>
            <Button
              className="bg-[#D9D9D9] rounded-xl text-[#232323]"
              endContent={<i className="ri-arrow-down-s-line ri-xl" />}
            >
              {displayProjectStatus(project.status)}
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            {supportedStatus.map((s) => (
              <DropdownItem key={s?.id} onClick={() => updateProjectStatus(s)}>
                {s?.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        <InsufficientCredits
          isOpen={creditsModalIsOpen}
          onOpen={creditsModalOnOpen}
          onClose={creditsModalOnClose}
          creditsModalOnOpenChange={(...args) => {
            get();
            creditsModalOnOpenChange(...args);
          }}
        />
      </>
    );
  } else {
    return (
      <Button className="bg-[#D9D9D9] rounded-xl text-[#232323]" disabled>
        {displayProjectStatus(project.status)}
      </Button>
    );
  }
};

export const HeaderEditAction = () => {
  const { project } = useProjectDetailContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (location.state && location.state.edit === true) {
      onOpen();
    }
  }, []);

  if (!['IN_ACTIVE', 'TODO'].includes(project.status)) {
    return null;
  }

  const onClick = () => {
    onOpen();
  };
  return (
    <>
      <Button
        variant="light"
        className={cn(
          'text-[#0D1216]',
          'data-[hover]:bg-transparent data-[pressed]:bg-transparent'
        )}
        startContent={<i className={cn('ri-pencil-line')} />}
        onClick={onClick}
      >
        Edit requirement
      </Button>
      <CreateProjectCustomTrigger
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate(location.pathname, {
            replace: true,
            state: {},
          });
        }}
        project={project}
        onOpen={onOpen}
      />
    </>
  );
};
